import React, { Component } from 'react';
import FormInput from './FormInput';
import { isValidPassword } from '../utils/auth';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';

import axios from 'axios';

const styles = {
  dialog: {
    heading: {
      fontFamily: 'Fresh Sans',
      marginLeft: 25,
      marginTop: 20,
      fontWeight: 'bold',
    },
    text: {
      fontSize: 15,
      fontFamily: 'Fresh Sans',
    },
    button: {
      fontSize: 15,
    },
  },
};

export default class EditUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      image: '',
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      mobile: props.user.mobile,
      email: props.user.email,
      passwordCurrent: '',
      passwordNew: '',
      passwordConfirm: '',
      secondDialog: false,
      message: [],
      success: true,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.closeDialog = this.closeDialog.bind(this);

    this.stripeUser = null;
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    const { firstName, lastName, mobile } = this.state;
    await axios
      .post('/user/update', { firstName, lastName, mobile })
      .then((response) => {
        if (
          response.data.hasOwnProperty('success') &&
          response.data.success === 1
        ) {
          this.setState(
            {
              message: [
                'Updated successfully',
                'The page will update shortly to reflect all updates.',
              ],
              succes: true,
              secondDialog: true,
            },
            () => {
              setTimeout(() => {
                window.location.reload(true);
              }, 6000);
            }
          );
        }
      })
      .catch((err) => {
        this.setState({
          message: err.response.data.errors,
          success: false,
          secondDialog: true,
        });
      });
  }

  async handlePasswordChange(e) {
    e.preventDefault();

    const {
      passwordConfirm,
      passwordCurrent,
      passwordNew,
      message,
      email,
    } = this.state;
    if (passwordCurrent === null || passwordCurrent === '') {
      message.push('Current password is empty.');
    }

    if (passwordNew === '') {
      message.push('New password can not be empty');
    }

    if (passwordConfirm !== passwordNew) {
      message.push('New password and confirm password are different');
    }

    if (passwordNew === email) {
      message.push('Password can not be same as your email');
    }

    if (message.length > 0) {
      this.setState({ secondDialog: true, success: false, message });
      return;
    }

    if (!isValidPassword(passwordNew)) {
      this.setState({ secondDialog: true, success: false });
      return;
    }

    try {
      await axios
        .post('/user/updatePassword', { passwordCurrent, passwordNew })
        .then((response) => {
          if (
            response.data.hasOwnProperty('success') &&
            response.data.success === 1
          ) {
            this.setState(
              {
                message: ['Password Updated'],
                success: true,
                secondDialog: true,
              },
              () => {
                //reset passwords after success
                setTimeout(() => {
                  this.setState({
                    passwordConfirm: '',
                    passwordCurrent: '',
                    passwordNew: '',
                  });
                }, 3000);
              }
            );
          }
        });
    } catch (err) {
      this.setState({
        message: err.response.data.errors,
        success: false,
        secondDialog: true,
      });
    }
  }

  closeDialog() {
    this.setState({ secondDialog: false }, () => {
      // second setState to avoid seeing default password policy before hiding the dialog
      setTimeout(() => {
        this.setState({ message: [], success: true });
      }, 5000);
    });
  }

  render() {
    let popupInfo = (
      <>
        <p style={{ fontWeight: 700 }}>Password Policy</p>
        <p>Password must contain:</p>
        <ul>
          <li>at least 8 characters</li>
          <li>at least one lowercase letter (a-z)</li>
          <li>at least one uppercase letter (A-Z)</li>
          <li>at least one number (0-9)</li>
        </ul>
      </>
    );
    if (this.state.message.length > 0) {
      popupInfo = this.state.message.map((msg, index) => (
        <p key={`msg-${index}`}>{msg}</p>
      ));
    }
    return (
      <div className='row edit-user-form'>
        <Dialog open={this.state.secondDialog}>
          <DialogContent style={styles.dialog.text}>{popupInfo}</DialogContent>
          <DialogActions>
            <button
              className={`btn btn-${this.state.success ? 'success' : 'danger'}`}
              onClick={this.closeDialog}
            >
              Close
            </button>
          </DialogActions>
        </Dialog>
        <div
          className='col-md-6 space-small-top space-small-bottom'
          style={{ backgroundColor: '#f8f9fa' }}
        >
          <div className='row'>
            <h2 className='col-sm-12 text-center'>Edit Personal Information</h2>
            <form onSubmit={this.handleFormSubmit}>
              <div className='col-sm-6 vetchat-from-input'>
                <label className=''>First Name</label>
                {FormInput(this, 'First Name', 'firstName', 'text')}
              </div>
              <div className='col-sm-6 vetchat-from-input'>
                <label className=''>Last Name</label>
                {FormInput(this, 'Last Name', 'lastName', 'text')}
              </div>
              <div className='col-sm-12 vetchat-from-input'>
                <label className=''>Email</label>
                {/* {FormInput(this, "Email", "email", "email")} */}
                <input disabled value={this.state.email} />
              </div>
              <div className='col-sm-12 vetchat-from-input'>
                <label className=''>Mobile</label>
                {FormInput(this, 'Mobile', 'mobile', 'text')}
              </div>
              <div className='col-sm-12 action-buttons'>
                <button type='submit' className='btn btn-vetchat2'>
                  Save
                </button>
                <button
                  className='btn btn-transparent'
                  type='button'
                  onClick={this.props.closeDialog}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='col-md-6 space-small-top space-small-bottom'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='update-password'>
                <h2 className='text-center'>Update Password</h2>
                <p className='text-center'>
                  Provide current password and new password to updated your
                  password
                </p>
                <div className='row'>
                  <form onSubmit={this.handlePasswordChange}>
                    <div className='col-sm-12 vetchat-from-input'>
                      <label className=''>Current Password</label>
                      <input
                        type='password'
                        // placeholder="Current password"
                        value={this.state.passwordCurrent}
                        onChange={(e) => {
                          this.setState({
                            passwordCurrent: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className='col-sm-6 vetchat-from-input'>
                      <label className=''>New Password</label>
                      <input
                        type='password'
                        // placeholder="New password"
                        value={this.state.passwordNew}
                        onChange={(e) => {
                          this.setState({ passwordNew: e.target.value });
                        }}
                      />
                    </div>
                    <div className='col-sm-6 vetchat-from-input'>
                      <label className=''>Confirm New Password</label>
                      <input
                        type='password'
                        // placeholder="New password again"
                        value={this.state.passwordConfirm}
                        onChange={(e) => {
                          this.setState({
                            passwordConfirm: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className='col-sm-12 action-buttons'>
                      <button type='submit' className='btn btn-vetchat2'>
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
