import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const VetConsultations = (props) => {
  const { consultations } = props.user;

  // let ConsultsList = []

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-sm-12'>
          <h3>
            <i className='fa fa-user-md' /> {props.user.firstName}{' '}
            {props.user.lastName}
            <small>({props.user.email})</small>
          </h3>

          <div>
            {props.user && props.user.type === 3 && (
              <span>
                <Link to='/admin' className='btn btn-vetchat'>
                  Admin Manager
                </Link>
              </span>
            )}
            <span style={{ marginLeft: '20px' }}>
              <Link to='/logout' className='btn btn-transparent'>
                Logout
              </Link>
            </span>
          </div>
          <hr />
        </div>
        <div className='col-sm-12'>
          <h3>
            Available Consultations
            <br />
            <small>
              Last Updated:&nbsp;
              {props.loadedTIme !== null
                ? moment(props.loadedTime).format('LTS')
                : ''}
            </small>
          </h3>
          <div className='row'>
            <div className='col-xs-10 col-xs-offset-1'>
              <p className='alert alert-info only-mobile'>
                Scroll the table below for more consult information if needed
              </p>
              <div className='table-responsive'>
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th># ID</th>
                      <th>
                        <select
                          value={props.display}
                          onChange={(e) => {
                            props.changeSorting(e.target.value);
                          }}
                        >
                          <option>All</option>
                          <option>Needs Help</option>
                          <option>In Progress</option>
                          <option>Completed</option>
                        </select>
                      </th>
                      <th>User and Pet</th>
                      <th>Consult Type</th>
                      {props.user.type >= 2 && <th>Vet</th>}
                      <th>Start</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ConsultationsList(
                      consultations,
                      props.user.type,
                      props.display
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConsultationsList = (consultations, userType, display) => {
  let List = [];
  for (let index in consultations) {
    const consult = consultations[index];

    // Skip according to selected display
    if (display === 'Available') {
      if (consult.vet_name !== null) {
        continue;
      }
    } else if (display === 'Completed') {
      if (consult.end === null) {
        continue;
      }
    } else if (display === 'In Progress') {
      if (consult.vet_name === null || consult.end !== null) {
        continue;
      }
    }

    const consultLink =
      consult.type === 1
        ? `/text-chat/${consult.id}`
        : `/video-chat/${consult.id}`;
    let consultText, consultClass;
    if (consult.end === null && consult.vet_name === null) {
      consultText = 'Needs Help';
      consultClass = 'btn btn-info';
    } else if (consult.end === null && consult.vet_name !== null) {
      consultClass = 'btn btn-success';
      consultText = 'In Progress';
    } else {
      consultClass = 'btn btn-transparent';
      consultText = 'View Details';
    }

    // Display only latest 50
    /* if (List.length >= 50) {
      break;
    } */

    List.push(
      <tr key={`consult-${consult.id}`}>
        <td>{consult.id}</td>
        <td>
          <Link to={consultLink} className={consultClass}>
            {consultText}
          </Link>
        </td>
        <td>
          <b>
            <Link to={consultLink}>{consult.pet.name}</Link>
          </b>
          <br />
          {consult.user.firstName} {consult.user.lastName} <br />
          <small>({consult.user.email})</small>
        </td>
        <td>{consult.type === 1 ? 'Text' : 'Video'}</td>
        {userType >= 2 && (
          <td>
            {consult.vet_name}
            <br />
            {consult.vet_start !== null &&
              moment(consult.vet_start).format('MMM Do YYYY, hh:mm A')}
            <br />
            {consult.end !== null &&
              moment(consult.end).format('\nMMM Do YYYY, hh:mm A')}
          </td>
        )}

        <td>{moment(consult.start).format('MMM Do YYYY, hh:mm A')}</td>
      </tr>
    );
  }
  return List;
};

export default VetConsultations;
