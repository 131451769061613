import React from 'react';

const FormInput = ({
  name,
  type,
  value,
  field,
  setField,
  disabled,
  ...rest
}) => {
  return (
    <input
      type={type ? type : 'text'}
      placeholder={name}
      value={value ? value : ''}
      onChange={(e) => {
        const newValue = e.target.value;
        setField(field, newValue);
      }}
      disabled={disabled}
      {...rest}
      className='form-input'
    />
  );
};

export default FormInput;
