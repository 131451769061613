import React, { Component } from 'react';
import Vets from './Vets';
import { Link } from 'react-router-dom';

export default class AdminView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: 'vets',
    };
  }

  componentDidMount() {
    const UserData = localStorage.getItem('user');
    if (UserData) {
      //check current user is admin
      const user = JSON.parse(UserData);

      if (user.type !== 3) {
        this.props.history.push('/myaccount');
      }
    } else {
      this.props.history.push('/');
    }

    document.title = 'Admin | Vetchat';
    if (this.props.match.params.hasOwnProperty('view')) {
      this.setState({ activeSection: this.props.match.params.view });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.view !== undefined &&
      this.props.match.params.view !== undefined &&
      prevProps.match.params.view !== this.props.match.params.view
    ) {
      this.setState({ activeSection: this.props.match.params.view });
    }

    if (
      prevProps.match.params.view !== undefined &&
      this.props.match.params.view === undefined
    ) {
      this.setState({
        activeSection: 'resources',
      });
    }

    if (
      prevProps.match.params.view === undefined &&
      this.props.match.params.view !== undefined
    ) {
      this.setState({ activeSection: this.props.match.params.view });
    }
  }

  render() {
    const { activeSection } = this.state;
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            <h1>
              Admin Manager
              <span className='pull-right'>
                <Link to='/myaccount' className='btn btn-vetchat'>
                  My Account
                </Link>
              </span>
            </h1>

            <div className='adminMenu space-small-top space-small-bottom'>
              <ul className='nav nav-pills'>
                {/* <li className={activeSection === 'resources' ? 'active' : ''}>
                  <Link to='/admin/resources'>Resources</Link>
                </li> */}
                <li className={activeSection === 'vets' ? 'active' : ''}>
                  <Link to='/admin/vets'>Vets</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-sm-12'>
            {/* {activeSection === 'resources' && <Resources />} */}
            {activeSection === 'vets' && <Vets />}
          </div>
        </div>
      </div>
    );
  }
}
