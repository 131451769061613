import React from 'react';

/**
 * Generate a form input block
 * @param {object} component
 * @param {string} name
 * @param {string} variable
 * @param {string} type
 */
const FormInput = (
  component,
  name,
  variable,
  type = 'text',
  onChangeCB,
  focusOutCB,
  colClass,
  required
) => {
  // colClass = (colClass === undefined) ? "col-sm-8 col-sm-push-2 form-group" : colClass + " form-group";
  colClass = colClass === undefined ? 'form-group' : colClass + ' form-group';
  required = required === undefined ? false : required;

  const reqField = required ? (
    // <div className="col-sm-1">
    <span className='required-star' style={{ color: 'red' }}>
      *
    </span>
  ) : (
    // </div>
    ''
  );

  const onChange =
    onChangeCB !== undefined && onChangeCB !== null
      ? onChangeCB
      : function (event) {
          component.setState({ [variable]: event.target.value });
        };

  const onFocusOut =
    focusOutCB !== undefined && focusOutCB !== null
      ? focusOutCB
      : function (event) {};

  return (
    <div className={colClass}>
      {reqField}
      <input
        type={type}
        className='form-control'
        placeholder={name}
        onChange={onChange}
        onBlur={onFocusOut}
        value={component.state[variable]}
        autoComplete={type === 'password' ? 'off' : 'on'}
      />
    </div>
  );
};

export default FormInput;
