import React, { Component } from 'react';
import axios from 'axios';
import FormInput from '../../components/FormInput';
import Loading from '../../components/Loading';

export default class Vets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vets: null,
      isLoading: false,
      vetEmail: '',
      user: {},
      status: '',
    };

    this.loadVets = this.loadVets.bind(this);
    this.findUser = this.findUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.clearStatus = this.clearStatus.bind(this);
  }

  componentDidMount() {
    this.loadVets();
  }

  loadVets() {
    this.setState({ isLoading: true });
    axios
      .get('/user/vets')
      .then((res) => {
        this.setState({ vets: res.data, isLoading: false });
      })
      .catch((err) => {
        console.log('error getting vets: ', err);
        this.setState({ isLoading: false });
      });
  }

  findUser() {
    this.setState({ isLoading: true });
    const { vetEmail } = this.state;
    axios
      .get(`/user/email/${vetEmail}`)
      .then((res) => {
        if (res.data !== null) {
          this.setState({ user: res.data });
        }
      })
      .catch((err) => {
        console.log('error loading user');
      });
    this.setState({ isLoading: false });
  }

  // update user type (to vet 2 or user 1 )
  updateUser(id, isVet = null) {
    const { vets } = this.state;
    this.setState({ isLoading: true });
    axios
      .put(`user/type/${id}`)
      .then((res) => {
        if (isVet === null) {
          vets.users.push(res.data);
          this.setState({
            user: {},
            vets,
            status: `User ${res.data.email} added as vet`,
          });
        } else {
          const remainingVets = vets.users.filter((vet) => vet.id !== id);

          this.setState({
            vets: { users: remainingVets },
            status: `Vet ${res.data.email} changed to user`,
          });
        }
      })
      .catch((err) => {
        console.log('error udating user: ', err);
      });

    this.setState({ isLoading: false });
    this.clearStatus();
  }

  clearStatus() {
    setTimeout(() => {
      this.setState({ status: '' });
    }, 3000);
  }

  render() {
    const { isLoading, user, vets, status } = this.state;

    let mapVets = [];
    if (vets === null || vets.users === null) {
      mapVets.push(
        <tr key={`no-vets`}>
          <td colSpan='3'>No Vets</td>
        </tr>
      );
    } else {
      console.log(vets);
      vets.users.forEach((vet) =>
        mapVets.push(
          <tr key={`vet-${vet.id}`}>
            <td>
              {vet.firstName} {vet.lastName}
            </td>
            <td>{vet.email}</td>
            <td>
              <button
                className='btn btn-danger'
                onClick={() => {
                  this.updateUser(vet.id, 1);
                }}
              >
                Make User
              </button>
            </td>
          </tr>
        )
      );

      console.log(mapVets);
    }

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div className='row vets-manager'>
        <h2 className='col-sm-12'>Vets Management</h2>
        <div className='jumbotron col-sm-12'>
          <div className='row'>
            <div className='col-sm-12'>
              <label>Find Vet by email :</label>
            </div>
            {FormInput(
              this,
              'Find Vet',
              'vetEmail',
              'email',
              null,
              null,
              'col-sm-6'
            )}
            <div className='col-sm-6'>
              <button
                className='searchVet btn btn-success'
                style={{ marginTop: 16 }}
                onClick={this.findUser}
              >
                Search
              </button>
            </div>

            {user !== null && user.hasOwnProperty('email') && (
              <>
                <div className='clear' />
                <div className='col-sm-12'>
                  <h3>Matching User</h3>
                  <div>
                    <p className='alert alert-info'>
                      Name: {user.firstName} {user.lastName}
                      <br />
                      Email: {user.email}
                      <br />
                      <span className=''>
                        Type:{' '}
                        {user.type === 3
                          ? 'Admin'
                          : user.type === 2
                          ? 'Vet'
                          : 'User'}
                      </span>
                      <br />
                      <button
                        className='btn btn-danger'
                        onClick={() => {
                          this.updateUser(user.id);
                        }}
                      >
                        Make{' '}
                        {user.type !== null && user.type > 1 ? 'User' : 'Vet'}
                      </button>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {status !== '' && (
          <div className='col-sm-12 alert alert-info'>
            <p>{status}</p>
          </div>
        )}
        <div className='col-sm-12 listVets table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>{mapVets}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
