import React, { Component, Fragment } from 'react';
import { PAGES } from '../constants/constants';
import { Link } from 'react-router-dom';

const styles = {
  videoColor: {
    backgroundColor: '#ee6e2e',
  },
  chatColor: {
    backgroundColor: '#ee6e2e',
  },
};

export default class Home extends Component {
  componentDidMount() {
    const thisPage = PAGES['home'];
    document.title = thisPage.title;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.getElementById('main-content').style.margin = '0 0 0';
  }

  render() {
    return (
      <Fragment>
        <div className='container-fluid home-banner'>
          <div className='row'>
            <div className='col-sm-5 col-sm-offset-7'>
              <div className='banner-content'>
                <h3>Chat with a vet online</h3>
              </div>
            </div>
          </div>
        </div>
        <div className='container homePage'>
          <div className='row'>
            {/* TOp COntent */}
            <div className='col-sm-12  top-content'>
              <h2 className='section-title'>Chat with a Vet online</h2>
              <p className='desc'>
                <b>Vetchat </b>connects you with an experienced Australian
                licensed Veterinarian via video or text, 6am - midnight AEDT
                every day.
              </p>
              <p className='desc'>Our Vets are on standby to help.</p>
            </div>

            {/*** Chat Packages ***/}
            <div
              className='col-sm-12'
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              <div className='purchase-table text-center'>
                <div className='purchase-header'>
                  <div className='purchase-title text-center'>
                    <span className='heading'>
                      Online Veterinary Consultation
                    </span>
                  </div>
                  <div className='purchase-price'>
                    <h3 className='price'>
                      <span>$39.95</span>
                      <small style={{ textTransform: 'none' }}>
                        per consultation
                      </small>
                    </h3>
                  </div>
                </div>
                <div className='purchase-type'>
                  {/* chat consult */}
                  <div className='type type-chat'>
                    <div className='details'>
                      <ul>
                        <li>
                          <i className='fa fa-check' />
                          One (1) consultation
                        </li>
                        <li>
                          <i className='fa fa-check' />
                          Upload pics and video
                        </li>
                        <li>
                          <i className='fa fa-check' />
                          Vet help within minutes
                        </li>
                      </ul>
                      <span>
                        <Link
                          to={'chat-purchase'}
                          className='btn'
                          style={styles.chatColor}
                        >
                          chat now
                        </Link>
                        <Link
                          to={'video-purchase'}
                          className='btn'
                          style={styles.videoColor}
                        >
                          video now
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <p className='text-center' style={{ marginTop: 16 }}>
                PLEASE NOTE: in the event of an emergency, please see your local
                Vet.
                <br />
                Vetchat Vets are not able to prescribe your pet medication.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
