import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormInput from '../components/FormInputFunctional';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';

const emptyUser = {
  firstName: '',
  lastName: '',
  email: '',
  clinic: '',
  consultTime: '',
};

const ClinicVideo = ({ history, ...props }) => {
  const [formData, setFormData] = useState({
    user: { ...emptyUser },
  });
  const [clinics, setClinics] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState('');

  const timeNow = moment();
  const timeFormat = 'YYYY-MM-DD[T]HH:mm';

  //isloggedin user has token
  if (localStorage.getItem('token')) {
    const user = JSON.parse(localStorage.user);

    if (+user.type < 2) {
      //goto homepage
      history.push('/');
    }
  } else {
    //goto homepage for not loggedin
    history.push('/');
  }

  //componentDidMount
  useEffect(() => {
    async function intialCheckAndLoadData() {
      //check is logged in as vet

      //import pet clinics
      await axios
        .get('/clinics')
        .then((res) => {
          //sort data alphabetically before displaying
          const clinicsData = res.data;
          clinicsData.sort((a, b) => {
            if (a.clinic > b.clinic) {
              return 1;
            } else if (b.clinic > a.clinic) {
              return -1;
            } else {
              return 0;
            }
          });

          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
          document.getElementById('main-content').style.margin = '0 0 0';
          setClinics(clinicsData);
        })
        .catch((err) =>
          console.log('Error loading clinics', err.response.data)
        );

      setField('user.consultTime', moment().format(timeFormat));
    }

    intialCheckAndLoadData();
    //eslint-disable-next-line
  }, []);

  //setFormData for user and pet
  const setField = (field, data) => {
    const fields = field.split('.');
    const currentData = Object.assign({}, formData);
    currentData[fields[0]][fields[1]] = data;
    setFormData(currentData);
  };

  //select field options
  const ClinicsOptions =
    clinics.length === 0
      ? null
      : clinics.map((clinic) => (
          <option key={`clinic-${clinic.id}`} value={+clinic.id}>
            {clinic.clinic}
          </option>
        ));

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    //check time within 72 hours
    let timeValid = false;
    const validationError = [];
    const SelectedTime = moment(formData.user.consultTime, timeFormat);
    const difference = SelectedTime.diff(moment(), 'hours');

    //selected time before current time
    if (SelectedTime.isBefore(moment())) {
      validationError.push('Consult time can not be before current time');
    }

    //time is after more than 72 hours
    if (+difference >= 72) {
      validationError.push('Time selected is after more than 73 hours');
    }

    if (validationError.length > 0) {
      setErrors(validationError);
      setSubmitting(false);
    } else {
      timeValid = true;
    }

    if (timeValid === false) {
      return;
    }

    //submitting
    await axios
      .post('/user/clinicVideo', formData.user)
      .then((result) => {
        if (result.data.consultation) {
          setMessage('Successfully created new consult');
          //reset form after creating consult
          setFormData({ user: { ...emptyUser } });
          window.location.pathname = '/myaccount';
        } else {
          setMessage('failed to create newConsult');
        }
        setTimeout(() => {
          setMessage('');
        }, 6000);
      })
      .catch((err) => {
        console.log('failed creating video consult for user.\n', err);
      });

    setTimeout(() => {
      setSubmitting(false);
    }, 5000);
  };

  const mapErrors = () => {
    if (errors.length === 0) {
      return null;
    }

    return errors.map((err, index) => (
      <p key={`error-${index}`}>&bull;&nbsp;{err}</p>
    ));
  };

  return (
    <>
      <div className='container-fluid petstock-clinic-video'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='container'>
              <div className='row'>
                <div className='col-sm-6 col-sm-offset-3'>
                  <h3>Clinic Video</h3>
                  <div className='form-clinicVideo'>
                    <form onSubmit={handleFormSubmit}>
                      <FormInput
                        name={`First Name`}
                        type={'text'}
                        value={formData.user.firstName}
                        setField={setField}
                        field={'user.firstName'}
                      />
                      <FormInput
                        name={`Last Name`}
                        type={'text'}
                        value={formData.user.lastName}
                        setField={setField}
                        field={'user.lastName'}
                      />
                      <FormInput
                        name={`Email`}
                        type={'email'}
                        value={formData.user.email}
                        setField={setField}
                        field={'user.email'}
                        required
                      />
                      <label>
                        Consult Time
                        <small style={{ fontWeight: 400 }}>
                          &nbsp;(in future upto 36 hours)
                        </small>
                        :
                      </label>
                      <FormInput
                        name={`Email`}
                        type={'datetime-local'}
                        value={formData.user.consultTime}
                        setField={setField}
                        field={'user.consultTime'}
                        min={moment().format(timeFormat)}
                        max={timeNow.add(36, 'h').format(timeFormat)}
                        required
                      />
                      {/*  <FormInput
                      name={`Mobile`}
                      type={'text'}
                      value={formData.user.mobile}
                      setField={setField}
                      field={'user.mobile'}
                    /> */}

                      {/* Clinic value can be avaoided as logged in user has clinic id on server saved on passport data */}
                      <label className='sr-only'>Clinic</label>
                      <select
                        value={+formData.user.clinic}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setField('user.clinic', +newValue);
                        }}
                      >
                        <option value=''>Select Clinic</option>
                        {ClinicsOptions}
                      </select>
                      <button
                        type='submit'
                        disabled={submitting}
                        className='btn btn-vetchat'
                        style={{ marginTop: 16 }}
                      >
                        Add
                      </button>
                      {message && <p>{message}</p>}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dialog for displkaying error messages */}
      <Dialog
        open={errors && errors.length > 0}
        onClose={() => {
          setErrors([]);
        }}
        maxWidth='md'
      >
        <div
          className='alert alert-warning'
          style={{ marginBottom: 0, padding: 32 }}
        >
          <h4>Errors</h4>
          {mapErrors()}
        </div>
      </Dialog>
    </>
  );
};

export default ClinicVideo;
