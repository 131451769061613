import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PropagateLoader } from 'react-spinners';
import { CONSULTATION_TYPES, PET_TYPES } from '../constants/constants';
import { Dialog, DialogContent } from '@material-ui/core';
import loadStripeJs from '../utils/loadStripeJs';
import PurchaseForm from '../components/PurchaseForm';
import { isEmail } from '../utils/auth';

import isNZ from '../utils/isNZ';

const FormInput = ({
  name,
  type,
  value,
  field,
  setField,
  disabled,
  ...rest
}) => {
  return (
    <input
      type={type ? type : 'text'}
      placeholder={name}
      value={value ? value : ''}
      onChange={(e) => {
        const newValue = e.target.value;
        setField(field, newValue);
      }}
      disabled={disabled}
      {...rest}
    />
  );
};

const PurchaseChat = (props) => {
  const [coupon, setCoupon] = useState({ isValid: false });
  const [couponCode, setCouponCode] = useState(null);
  const [formData, setFormData] = useState({
    user: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      clinic: '',
      postcode: '',
    },
    pet: { name: '', type: '', breed: '', query: '' },
    tc: false,
    emailSubscription: false,
  });
  const [saving, setSaving] = useState(false);
  const [petBreeds, setBreeds] = useState([]);
  const [errors, setErrors] = useState([]);
  // const [success, setSuccess] = useState(false);
  const [consult, setConsult] = useState(null);
  const [stripeJs, setStripeJs] = useState(false);
  const [stripeToken, setStripeToken] = useState(null);
  const [clinics, setClinics] = useState([]);
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [resetStripeForm, setResetStripeForm] = useState(false);

  //component Did Mount and url change
  useEffect(() => {
    async function loadPetBreeds() {
      //load all pet breeds from db
      await axios
        .get('pet/all/breeds')
        .then((res) => {
          const sortedBreeds = res.data.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
          setBreeds(sortedBreeds);
        })
        .catch((err) => {
          console.log('error getting breeds: ', err);
        });
    }

    async function loadPetStockClinics() {
      if (isNZ()) {
        setClinics([
          {
            id: 8002,
            clinic: 'Constellation Drive',
            corporate_split: 'Corportae',
            clinic_type: 'Hospital',
            address:
              '10 Constellation Drive, Mairangi Bay, Auckland, New Zealand, 632',
            state: 'NZ',
            telephone: '09 479 7609',
            email: 'umesh+cinicPS24@vetchat.com.au',
            business_partner: 'Tayla Wright',
            practice_manager: 'Bex Abel',
            head_vet: 'Dr Corrine Wigfall',
          },
        ]);
      } else {
        await axios
          .get('/clinics')
          .then((res) => {
            //sort data alphabetically before displaying
            const clinicsData = res.data;
            clinicsData.sort((a, b) => {
              if (a.clinic > b.clinic) {
                return 1;
              } else if (b.clinic > a.clinic) {
                return -1;
              } else {
                return 0;
              }
            });

            setClinics(clinicsData);
          })
          .catch((err) =>
            console.log('Error loading clinics', err.response.data)
          );
      }
    }

    async function setConsultType() {
      const { path } = props.match;
      const purchaseType = path.substr(1).split('-').shift();
      const consult = CONSULTATION_TYPES.filter(
        (cons) => purchaseType === cons.type
      ).shift();
      setConsult(consult);
    }

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.getElementById('main-content').style.margin = '0 0 0';
    setConsultType();
    loadPetBreeds();
    loadPetStockClinics();
    loadStripeJs(() => {
      setStripeJs(true);
    });

    //clear on component unmount
    return () => {
      document.getElementById('main-content').style.margin = '0 0 20px';
    };

    //eslint-disable-next-line
  }, [props.match.url]);

  //setFormData for user and pet
  const setField = (field, data) => {
    const fields = field.split('.');
    const currentData = Object.assign({}, formData);
    currentData[fields[0]][fields[1]] = data;
    setFormData(currentData);
  };

  //check coupon on DB
  const checkCoupon = async () => {
    await axios
      .post('/pay/coupon', { couponCode })
      .then((res) => {
        // console.log('coupon response from server', res.data);
        if (res.data === null || res.data.length === 0) {
          setCoupon({
            isValid: false,
            message: (
              <span className='text-danger'>
                <i className='fa fa-times' /> Invalid coupon
              </span>
            ),
          });
        } else {
          setCoupon({
            isValid: true,
            message: (
              <span className='text-success'>
                <i className='fa fa-check' /> Coupon applied
              </span>
            ),
            data: res.data,
          });
        }
      })
      .catch((err) => {
        console.log('Error from coupon on server\n', err);
        //set invlid coupon
        setCoupon({
          isValid: false,
          message: (
            <span className='text-danger'>
              <i className='fa fa-times' /> Invalid coupon
            </span>
          ),
        });
      });
  };

  const PetTypeOptions = PET_TYPES.map((type) => (
    <option key={`option-type-${type.id}`} value={type.id}>
      {type.name}
    </option>
  ));

  let PetBreedOptions = '';
  if (
    petBreeds.length > 0 &&
    formData.pet.type &&
    (+formData.pet.type === 1 || +formData.pet.type === 2)
  ) {
    const selectedBreeds = petBreeds.filter(
      (breed) => +breed.species_id === +formData.pet.type
    );

    if (selectedBreeds.length > 0) {
      PetBreedOptions = selectedBreeds.map((breed) => (
        <option key={`pet-breed-${breed.id}`} value={breed.id}>
          {breed.name}
        </option>
      ));
    }
  }

  let breedDisabled = true;
  if (
    (formData.pet.type !== null && formData.pet.type === 1) ||
    formData.pet.type === 2
  ) {
    breedDisabled = false;
  }

  const ClinicsOptions =
    clinics.length === 0
      ? null
      : clinics.map((clinic) => (
          <option key={clinic.id} value={clinic.id}>
            {clinic.clinic}
          </option>
        ));

  /***************************************************************
   * handle form submit and complete the purchase of chat/video consultation
   *
   *****************************************************************/
  const handlePurchase = async (e) => {
    e.preventDefault();

    //validating data
    setSaving(true);
    const errors = [];

    // check time before making any payments or validation
    await axios.get('/consultation/time').catch((err) => {
      // error received when time is beyond 6am and midnight SYD Time
      errors.push(
        ' Vetchat is currently offline (Online between 6:00am to 12:00am SYD Time). Please call your nearest Vet.'
      );
    });

    //checking card token generated from stripe
    if (stripeToken === null) {
      errors.push('Missing / invalid payment card details.');
      // setErrors(errors);
    }

    //check email
    if (!isEmail(formData.user.email)) {
      errors.push('Invalid Email address');
    }

    if (formData.user.firstName === null || formData.user.firstName === '') {
      errors.push('First name can not be empty');
    }

    if (formData.user.lastName === null || formData.user.lastName === '') {
      errors.push('Last name can not empty');
    }

    if (formData.user.mobile === null || formData.user.mobile === '') {
      errors.push('Phone can not be empty');
    }

    if (formData.pet.name === null || formData.pet.name === '') {
      errors.push('Pet name is required');
    }

    if (formData.pet.type === null || formData.pet.type === '') {
      errors.push('Pet type is required');
    }

    if (formData.pet.query === '') {
      errors.push('Pet issue is required');
    }

    if (
      formData.pet.type &&
      (formData.pet.type === 1 || formData.pet.type === 2) &&
      (formData.pet.breed === null || formData.pet.breed === '')
    ) {
      errors.push('Pet breed is required');
    }

    //check is erros are set
    if (errors.length > 0) {
      setErrors(errors);
      setSaving(false);
      return;
    }

    /************************************
     * NO Errors
     * -submit data and create consultation
     * -make stripe poayment
     */
    await axios
      .post('/pay', {
        card: { token: stripeToken },
        amount: discounted ? discounted : consult.price,
        consult: consult.title,
        user: formData.user.email,
        currency: isNZ() ? 'nzd' : 'aud',
      })
      .then(async (res) => {
        if (
          res.data.hasOwnProperty('status') &&
          res.data.status === 'succeeded'
        ) {
          //save token and customer data to session

          //create consultation for user and redirect
          await axios
            .post('/user/newUserConsultation', {
              ...formData.user,
              customer_id: res.data.customer.id,
              name: formData.pet.name,
              pet_species: formData.pet.type,
              pet_breed: formData.pet.breed,
              consult_type: consult.type === 'chat' ? 1 : 2,
              query: formData.pet.query,
            })
            .then(async (cons) => {
              const { consultation, token } = cons.data;
              await localStorage.removeItem('token');
              await localStorage.removeItem('singleToken');
              await localStorage.setItem('token', token);
              await localStorage.setItem('singleToken', token);
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + token;

              const consultation_type =
                consult.type === 'video' ? 'video-chat' : 'text-chat';

              props.history.push(`/${consultation_type}/${consultation.id}`);
            });
        } else {
          setErrors(['Failed to capture the payment']);
          return;
        }
      })
      .catch((err) => {
        // console.log('Error subscribing user', err);
        console.log(err.response);
        const errorResponse = err.response.data;

        // change stripe form data
        setResetStripeForm(true);
        setTimeout(() => {
          setResetStripeForm(false);
        }, 5000);
        if (errorResponse.hasOwnProperty('message')) {
          if (errorResponse.code === 'token_already_used') {
            setErrors(['Failed to make a payment. Try again.']);
          } else {
            setErrors([errorResponse.message]);
          }
        } else {
          setErrors(['Failed to make a payment. Try again.']);
        }
        setSaving(false);
      });

    //return null as divertion occurs in previous post
    return;
  };

  const listErrors = errors.map((er, index) => (
    <li key={`error-${index}`}>
      <span className='text-danger'>{er}</span>
    </li>
  ));
  const alertClass = errors.length > 0 ? 'alert-warning' : 'alert-success';

  const Loading = (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <PropagateLoader />
    </div>
  );

  const inputDisabled = false;

  let discounted = null;

  if (coupon.isValid) {
    let discountAmount = 0;
    if (coupon.data.amount_Off) {
      discountAmount = +coupon.data.amount_Off;
    } else if (coupon.data.percent_off) {
      discountAmount = +consult.price * (+coupon.data.percent_off / 100);
    }

    if (discountAmount > 0) {
      discounted = Number(+consult.price - +discountAmount).toFixed(2);
    }

    console.log('Discounted', discounted, '\ncoupon:  ', coupon);
  }

  return (
    <>
      <Dialog
        open={errors.length > 0}
        maxWidth={'md'}
        disableBackdropClick={true}
      >
        <DialogContent className={`alert alert-offer ${alertClass}`}>
          {errors.length > 0 && (
            <>
              <h2>Errors Occured</h2>
              <ul>{listErrors}</ul>
              <br />
              <button
                type='button'
                className='btn btn-transparent btn-block'
                onClick={() => {
                  setErrors([]);
                }}
              >
                Close
              </button>
            </>
          )}
        </DialogContent>
      </Dialog>
      {consult === null && Loading}

      {consult !== null && (
        <div className='container-fluid' style={{ backgroundColor: '#f8f9fb' }}>
          <div className='row'>
            <div className='col-xs-10 col-xs-offset-1'>
              <div
                className='purchase-container vetchat-purchase'
                style={{ backgroundColor: '#00c7fa' }}
              >
                <form onSubmit={handlePurchase}>
                  <div className='col'>
                    <div className='coupon'>
                      <h3 className='block-title'>{consult.title}</h3>
                      <div className='consult-price'>
                        <p style={{ marginBottom: 0 }}>
                          Price({isNZ() ? 'NZD' : 'AUD'}):{' '}
                          <span
                            className={`price ${
                              discounted ? 'discounted' : ''
                            }`}
                          >
                            ${consult.price}
                          </span>
                          {discounted && (
                            <span className='final discounted'>
                              ${discounted}
                            </span>
                          )}
                        </p>
                        <p style={{ fontSize: 12 }}>
                          PLEASE NOTE: in the event of an emergency, please see
                          your local Vet.
                          <br />
                          Vetchat Vets are not able to prescribe your pet
                          medication.
                        </p>
                      </div>
                      {/* <p>Exclusive offer to Petsure</p> */}
                      <div className='coupon-input hidden'>
                        <input
                          type='text'
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setCouponCode(newValue);
                          }}
                          placeholder='coupon code'
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              checkCoupon();
                            }
                          }}
                        />
                        <button
                          type='button'
                          onClick={checkCoupon}
                          className='btn-coupon-check'
                        >
                          <i className='fa fa-chevron-right' />
                        </button>
                      </div>
                      <div className='coupon-message hidden'>
                        {coupon && coupon.message}
                      </div>
                    </div>
                    <div className='petstock-clinic'>
                      <p>
                        <label className='text-left'>
                          <input
                            type='checkbox'
                            defaultChecked={existingCustomer}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              setExistingCustomer(newValue);
                            }}
                          />
                          <span>
                            I am an existing <br />
                            <b style={{ color: '#00acf6' }}>
                              {`<insert>`} Vet customer
                            </b>
                          </span>
                        </label>
                      </p>
                      <select
                        value={formData.user.clinic}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setField('user.clinic', +newValue);
                        }}
                        disabled={!existingCustomer}
                      >
                        <option value=''>Select Clinic</option>
                        {ClinicsOptions}
                      </select>
                    </div>
                    <div className='payment-stripe'>
                      {stripeJs === true && (
                        <PurchaseForm
                          amount={discounted ? discounted : consult.price}
                          consult={consult}
                          onComplete={setStripeToken}
                          email={formData.user.email}
                          setEmail={(data) => {
                            setField('user.email', data);
                          }}
                          resetStripeForm={resetStripeForm}
                        />
                      )}
                    </div>
                  </div>
                  <div className='col'>
                    <h3 className='block-title'>Your Pet Details</h3>
                    <FormInput
                      name={`Pet's Name`}
                      type={'text'}
                      value={formData.pet.name}
                      setField={setField}
                      field={'pet.name'}
                      disabled={inputDisabled}
                    />
                    <select
                      value={formData.pet.type ? formData.pet.type : ''}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setField('pet.type', +newValue);
                        //remove breed when not cat or dog
                        if (newValue !== 1 || newValue !== 2) {
                          setField('pet.breed', null);
                        }
                      }}
                      disabled={inputDisabled}
                    >
                      <option value=''>Pet Type</option>
                      {PetTypeOptions}
                    </select>
                    <select
                      value={formData.pet.breed ? formData.pet.breed : ''}
                      disabled={breedDisabled}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setField('pet.breed', newValue);
                      }}
                    >
                      <option value=''>Pet Breed</option>
                      {PetBreedOptions}
                    </select>
                    <textarea
                      value={formData.pet.query}
                      disabled={inputDisabled}
                      placeholder='Pet Issue'
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setField('pet.query', newValue);
                      }}
                    />
                  </div>
                  <div className='col'>
                    <h3 className='block-title'>Your Personal Details</h3>
                    <FormInput
                      name='First Name'
                      type={'text'}
                      value={formData.user.firstName}
                      setField={setField}
                      field={'user.firstName'}
                      disabled={inputDisabled}
                    />
                    <FormInput
                      name='Last Name'
                      type={'text'}
                      value={formData.user.lastName}
                      setField={setField}
                      field={'user.lastName'}
                      disabled={inputDisabled}
                    />
                    <FormInput
                      name='Email'
                      type={'email'}
                      value={formData.user.email}
                      setField={setField}
                      field={'user.email'}
                      disabled={inputDisabled}
                    />
                    <FormInput
                      name='Phone'
                      type={'text'}
                      value={formData.user.mobile}
                      setField={setField}
                      field={'user.mobile'}
                      disabled={inputDisabled}
                    />
                    <FormInput
                      name='Post Code'
                      type={'text'}
                      value={formData.user.postcode}
                      setField={setField}
                      field={'user.postcode'}
                      disabled={inputDisabled}
                    />
                    <p className='tc-agree'>
                      <label className='text-left'>
                        <input
                          type='checkbox'
                          defaultChecked={formData.tc}
                          style={{ verticalAlign: 'top', marginTop: 4 }}
                          onChange={(e) => {
                            const newValue = e.target.checked;
                            setFormData({ ...formData, tc: newValue });
                          }}
                        />
                        <span>
                          I consent to an online consultation and accept Vetchat
                          <a
                            href='https://www.vetchat.com.au/terms-and-conditions'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            &nbsp;terms and conditions
                          </a>{' '}
                          and{' '}
                          <a
                            href={'https://www.vetchat.com.au/privacy-policy'}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            privacy policy
                          </a>
                          . I understand I'll occasionally receive account
                          related emails.
                        </span>
                      </label>
                    </p>
                    <p className='tc-agree'>
                      <label className='text-left'>
                        <input
                          type='checkbox'
                          defaultChecked={formData.emailSubscription}
                          style={{ verticalAlign: 'top', marginTop: 4 }}
                          onChange={(e) => {
                            const newValue = e.target.checked;
                            setFormData({
                              ...formData,
                              emailSubscription: newValue,
                            });
                          }}
                        />
                        <span>
                          Send me pet tips, stuff I’ll really use, from real
                          Vets.
                        </span>
                      </label>
                    </p>
                    <div className='text-center' style={{ marginBottom: 20 }}>
                      <button
                        className='btn btn-pay btn-confirm'
                        disabled={!formData.tc}
                        type='submit'
                      >
                        <span>
                          {consult.type === 'video' ? 'Video' : 'Chat to'}
                        </span>{' '}
                        Vet now
                      </button>
                      <span className='submitting'>
                        {saving && <PropagateLoader color={'#36D7B7'} />}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchaseChat;
