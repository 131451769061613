import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';

const styles = {
  dialog: {
    heading: {
      fontFamily: 'Fresh Sans',
      marginLeft: 25,
      marginTop: 20,
      fontWeight: 'bold',
    },
    text: {
      fontSize: 15,
      fontFamily: 'Fresh Sans',
    },
    button: {
      fontSize: 15,
    },
  },
};

export default class AjaxError extends React.Component {
  constructor(props) {
    super(props);

    this.close = this.close.bind(this);

    this.state = {
      open: props.open,
      errors: props.errors,
    };
  }

  close() {
    this.setState({ open: false });
    this.props.action();
  }

  render() {
    return (
      <div>
        <Dialog open={this.state.open}>
          <p style={styles.dialog.heading}>Error</p>
          <DialogContent>
            <div className='alert alert-danger'>
              {this.state.errors.map((error, index) => {
                return (
                  <p style={{ fontFamily: 'Fresh Sans' }} key={index}>
                    {error}
                  </p>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <button className='btn btn-success' onClick={this.close}>
              Ok
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
