import React from 'react';
import axios from 'axios';

const UserConsult = (props) => {
  const token = props.match.params.token;
  const consult_id = props.match.params.consult_id;
  const consult_type = props.match.params.type;

  localStorage.removeItem('singleToken');
  localStorage.setItem('token', token);
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  window.location.pathname = '/' + consult_type + '-chat/' + consult_id;

  return <div>Connecting to consult</div>;
};

export default UserConsult;
