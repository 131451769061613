import React from 'react';
import { Link } from 'react-router-dom';
/**
 * Nav Bar Links
 * Display relevant nav links when user is signed in or signed out
 */
const NavLinks = function () {
  let links = [];
  links.push(
    <li key='video'>
      <Link to='/video-purchase'>Video Call</Link>
    </li>
  );
  links.push(
    <li key='chat'>
      <Link to='/chat-purchase'>Text Chat</Link>
    </li>
  );
  links.push(
    <li key='contact'>
      <Link to='/contact'>Contact</Link>
    </li>
  );

  //loggedin check and user check
  if (localStorage.getItem('token') && localStorage.getItem('user')) {
    const user = JSON.parse(localStorage.getItem('user'));
    // console.log('user', user);
    if (+user.type > 1) {
      links.push(
        <li key='cliniCVideo'>
          <Link to='/clinicVideo'>Clinic Video</Link>
        </li>
      );
    }

    links.push(
      <li key='myaccount'>
        <Link to='/myaccount'>My Account</Link>
      </li>
    );

    links.push(
      <li key='logout'>
        <Link to='/logout'>Logout</Link>
      </li>
    );
  } else {
    links.push(
      <li key='login'>
        <Link to='/login'>Login</Link>
      </li>
    );
  }

  return links;
};

export default NavLinks;
