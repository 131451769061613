// Core Libs
import React from 'react';
import { ConsultationView as VetchatTextChat } from 'vetchat-react';
// import ConsultationInfo from "vetchat-react/ConsultationInfo";
// import TextChat from "./TextChat";
import Loading from '../components/Loading';
import AjaxError from '../components/AjaxError';
import axios from 'axios';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

// Uitls
import { isDev } from '../utils/dev';
import { apiError } from '../utils/errors';
import { sendVetClinicEmail } from '../utils/VetClinicEmailHandler';

export default class VetchatText extends React.Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.getConsultationInfo = this.getConsultationInfo.bind(this);
    this.summaryNotes = this.summaryNotes.bind(this);

    let singleToken = localStorage.getItem('singleToken');
    if (localStorage.getItem('token') === null && singleToken !== null) {
      const singleTokenDecoded = jwtDecode(singleToken);
      const { exp } = singleTokenDecoded;
      if (Date.now() < exp * 1000) {
        // return false;
        console.log('valid');
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + singleToken;
      }
    }

    this.state = {
      consultation: [],
      consultation_id: null,
      pet_consultations: [],
      clinic: [],
    };
  }

  componentDidMount() {
    document.title = 'Text Chat | VetChat';
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    document.body.classList.add('chat-page');

    const { consultation_id } = this.props.match.params;

    if (consultation_id === undefined || isNaN(Number(consultation_id))) {
      this.props.history.push(`/pricing`);
      return;
    }

    this.setState({ consultation_id }, async () => {
      await this.getConsultationInfo();
      // await this.getVetClinic();
    });
  }

  componentWillUnmount() {
    document.body.classList.remove('chat-page');
  }

  handleError(error, errors) {
    return (
      <AjaxError
        open={error}
        errors={errors}
        // action={}
      />
    );
  }
  showLoading(title) {
    return <Loading title={title} />;
  }

  handleApiError(ele, error) {
    apiError(ele, error);
  }

  checkDev() {
    return isDev();
  }

  // Get Consultation Info from server
  async getConsultationInfo() {
    await axios
      .get(
        axios.defaults.baseURL + '/consultation/' + this.state.consultation_id
      )
      .then(async (response) => {
        if (response.data.hasOwnProperty('consultation')) {
          /* if (response.data.consultation.type === 2) {
            this.props.history.push(
              `/video-chat/${this.state.consultation_id}`
            );
            return;
          } */

          this.setState({
            consultation: response.data.consultation,
          });

          await this.getVetClinic();
        } else if (response.data.hasOwnProperty('errors')) {
          this.handleApiError(this, {
            response: { errors: response.data.errors },
          });
          this.props.history.push('/');
          return;
        }
      })
      .catch((e) => this.handleApiError(this, e));
  }

  //get vet clinic from consultation user data
  getVetClinic = async () => {
    const { user } = this.state.consultation;

    if (user.clinic !== null) {
      await axios
        .get(`/clinics/${+user.clinic}`)
        .then((result) => {
          this.setState({
            clinic: {
              name: result.data.clinic,
              email: result.data.email,
            },
          });
        })
        .catch((err) => console.log('Error getting user clinic', err));
    }
  };

  summaryNotes(data) {
    return sendVetClinicEmail(data);
  }

  render() {
    const { consultation } = this.state;
    return (
      <div className='container'>
        <div className='row'>
          <div className='col-sm-12'>
            {consultation.length === 0 && <Loading title={'consult info'} />}
            {consultation.length !== 0 && (
              <VetchatTextChat
                {...this.props}
                showLoading={this.showLoading}
                handleError={this.handleError}
                apiError={this.handleApiError}
                isDev={this.checkDev}
                api={axios.defaults.baseURL}
                axios={axios}
                consultation={consultation}
                moment={moment}
                summaryNotes={this.summaryNotes}
                vetClinics={this.state.clinic}
                app_module={`demo.vetchat.com.au`}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
