import React from 'react';
import { Link } from 'react-router-dom';
import NavLinks from './NavLinks';

export default class Header extends React.Component {
  render() {
    return (
      <header className='petstock-header' style={{ paddingBottom: 0 }}>
        <nav className='navbar navbar-default'>
          <div className='navbar-header text-center'>
            {/* Mobile Hamburger Button */}
            <div className='hidden-md hidden-sm hidden-lg'>
              <button
                type='button'
                className='navbar-toggle collapsed'
                data-toggle='collapse'
                data-target='#mobile-menu'
                aria-expanded='false'
              >
                <span className='sr-only'>Toggle navigation</span>
                <span className='icon-bar' />
                <span className='icon-bar' />
                <span className='icon-bar' />
              </button>
            </div>

            {/* Site Title/Logo */}
            <Link to='/' className='home-link'>
              <img
                className='header-logo'
                alt=''
                src='/images/vetchat-logo.png'
              />
              <span>- DEMO</span>
            </Link>
          </div>
        </nav>
        <nav className='navbar navbar-default header-second '>
          {/* Nav Links */}
          <div
            className='collapse navbar-collapse text-center'
            id='mobile-menu'
          >
            <ul className='nav navbar-nav'>
              <NavLinks />
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}
