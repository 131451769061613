// Core React
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Node Libs
import axios from 'axios';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import NotFound from './components/pages/NotFound';

// Views
import Home from './views/Home';
import Login from './views/Login';
import MyAccount from './views/MyAccount';
import ContactPage from './views/pages/contact';
import VetchatText from './views/VetchatText';
// import VetchatVideo from './views/VetchatVideo';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import PurchaseChat from './views/PurchaseChat';
import AdminView from './views/AdminView/AdminView';
import CliniCVideo from './views/ClinicVideo';
import UserVideo from './views/UserVideo';
import UserConsult from './views/UserConsult';

// Uitls
import { isDev } from './utils/dev';

// CSS
import 'react-datepicker/dist/react-datepicker.css';

// Ajax Setup
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

if (isDev()) {
  axios.defaults.baseURL = 'http://localhost:5443';
} else {
  axios.defaults.baseURL = 'https://api.vetchat.com.au';
}

let token = localStorage.getItem('token');
if (token !== null) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

axios.defaults.headers.common['app_module'] = 'demo.vetchat.com.au';

/**
 * Sing Off Component
 */
class SignOff extends React.Component {
  // Delete token and redirect to login page
  constructor(props) {
    super(props);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('pets');
    //remove axios authorization;
    delete axios.defaults.headers.common['Authorization'];
    props.history.push('/login');
  }

  render() {
    return <div />;
  }
}

const AppRouter = () => (
  <Router>
    <div>
      <Header />
      <div id='main-content'>
        {/* Route Outlet */}
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/contact' exact component={ContactPage} />
          <Route path='/video-purchase' component={PurchaseChat} />
          <Route path='/chat-purchase' component={PurchaseChat} />
          <Route path='/login' exact component={Login} />
          <Route path='/logout' exact component={SignOff} />
          <Route path='/myaccount' exact component={MyAccount} />
          <Route path='/signoff' exact component={SignOff} />
          <Route path='/text-chat/:consultation_id' component={VetchatText} />
          <Route path='/text-chat' exact component={VetchatText} />
          <Route path='/video-chat/:consultation_id' component={VetchatText} />
          <Route path='/video-chat' exact component={VetchatText} />
          <Route path='/forgotpassword' exact component={ForgotPassword} />
          <Route path='/resetpwd' exact component={ResetPassword} />
          <Route path='/resetpwd/:code' component={ResetPassword} />
          <Route path='/clinicVideo' component={CliniCVideo} />
          <Route
            path='/userVideo/:customer_id/:consult_id'
            component={UserVideo}
          />
          <Route
            path='/consult/:consult_id/:token/:type'
            component={UserConsult}
          />
          <Route path='/admin' exact component={AdminView} />
          <Route path='/admin/:view' exact component={AdminView} />
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  </Router>
);

export default AppRouter;
