import React, { Fragment } from 'react';

/* export const HUBSPOT_INFO = {
    portalId: "4490245",
    signupFormId: "6b169345-6ed2-48f7-83ad-6bdf49f3eb3b",
}; */

export const PET_TYPES = [
  { id: 1, name: 'Dog' },
  { id: 2, name: 'Cat' },
  { id: 3, name: 'Rabbit' },
  { id: 4, name: 'Guinea Pig' },
  { id: 5, name: 'Bird' },
  { id: 6, name: 'Rat' },
  { id: 7, name: 'Mouse' },
  { id: 8, name: 'Snake' },
  { id: 9, name: 'Turtle' },
  { id: 10, name: 'Unicorn' },
  { id: 11, name: 'Other' },
];

export const VETS = [
  {
    vet: 'Dr. Claire',
    fullName: 'Dr. Claire Jenkins',
    position: 'Our resident Vet and VetChat Co-Founder',
    description: '',
    about: [
      '15 years of experience',
      'Bachelor of Veterinary Science (BVSc), MANZCVS (SAM)',
      'Mum to gorgeous Kelpie ‘Red’',
    ],
    image: 'circle_claire.png',
  },
  {
    vet: 'Dr. Liz',
    fullName: 'Dr. Liz Shaw',
    position: 'Small Animal Veterinarian',
    description: '',
    about: [
      '7 years of experience',
      'Bachelor of Veterinary Science (BVSc, Hons)',
      'Mum to Maisy and Niko, both rescued Ragdolls',
    ],
    image: 'circle_liz.png',
  },
  {
    vet: 'Dr. Lucy',
    fullName: 'Dr. Lucy Crane',
    position: 'Small and Mixed Animal Veterinarian',
    description: '',
    about: [
      '9 years of experience',
      'Bachelor of Veterinary Medicine (BVetMed)',
      'Our Sunshine Coast - based Vet',
    ],
    image: 'circle_lucy.png',
  },
];

export const TESTIMONIALS = [
  {
    from: 'Benny, QLD',
    text: [
      '"We highly recommend Dr Claire and VetChat to anyone who is umming and ahhing about taking their beloved pet to the Vet.',
      'Our puppy Tonks was having a bad run with illness and the Vet bills were accumulating.',
      'VetChat helped us to understand what Tonks was going through, what the next steps should be and what we could do for her, that day, without the adding to the Vet debt".',
    ],
  },
  {
    from: 'Mark, VIC',
    text: [
      '"The after hours service, prior to going to a Vet hospital could be a vital decision between waiting for a Vet to open, which could be fatal, or even just understanding the level of severity of your pets problem.',
      'It could be something minor and therefore not needing the after hours Vet service or hospital.',
      'VetChat provides professional support to help you make a decision on the next actions to take to care for your pet".',
    ],
  },
  {
    from: 'Lauren, NT',
    text: [
      '"What started out as a second opinion became a journey and I cannot praise VetChat highly enough.',
      "Although I live in a city there was no Vet willing to invest the time and patience into our boy Fella’s unique situation. Claire went above and beyond in ensuring we had the support in getting a resolution into Fella's skin condition.",
      'Claire provided the best veterinary service I’ve ever had and I would not hesitate to use or recommend VetChat to anyone in the future".',
    ],
  },
  {
    from: 'Nicoleta, NSW',
    text: [
      '"Dr Claire was so helpful, and put my mind at ease at a time when I couldn’t get to my local Vet. The video call made all the difference in knowing what action to take with my puppy Pepper.',
      'Further still, Dr Claire followed up the next day to see how Pepper was feeling.',
      'Thank you so much Dr Claire and VetChat, I can’t wait to share my experience with others".',
    ],
  },
  {
    from: 'Brooke, VIC',
    text: [
      '"I used VetChat one evening when our local veterinary clinics were closed. My pup had blood in his stools and we were concerned. The service I received was exceptional and I really felt that we were being taken care of, despite not presenting at a clinic with our pup.',
      'VetChat helped us to understand what Tonks was going through, what the next steps should be and what we could do for her, that day, without the adding to the Vet debt".',
    ],
  },
  {
    from: 'Anthony, NSW',
    text: [
      '"VetChat has become an essential part of my dog’s health care. Since moving to the country it has become more important than ever as I’m able to get a 2nd opinion regarding what I’m being told by my local Vet.',
      'The ability to be able to have a video link up to someone from my own home, in a relaxed atmosphere makes for far better questions and answers. I really wouldn’t be without it."',
    ],
  },
  {
    from: 'Rebecca, VIC',
    text: [
      '"Thank goodness for VetChat! Our poor pup ran away because of the fireworks at new years. She returned the next morning, limping with cuts on her feet. The Vet was closed, and I was home alone with the kids anyway, so couldn’t have got the Vet.',
      'Instead I jumped online to Claire at VetChat. She talked me through Flo\'s injuries, and what I could do in the interim before I could get to the Vet. When we got to the Vet a few days later, the Vet commended me on our pup’s clean wounds – thanks for the lessons on wound care VetChat!"',
    ],
  },
  {
    from: 'Felicity, VIC',
    text: [
      '"VetChat is 000 for animals! When our small 4 week old pup became sick I thought \'if only I could just call a Vet and send a picture to them so they can help us without having to up end the little guy and drag him to a clinic’.',
      'I suspected a virus and I didn’t want to leave the house with him. I knew how busy the local Vet was and a house call could take hours and be expensive.',
      'So I looked online and there was VetChat. What a godsend! I can’t emphasise enough just how helpful this service is. To have such an intelligent, knowledgeable warm and helpful person on call on my mobile really meant the difference between life and death for this pup. With Dr Claire’s help I was able to nurse him around the clock which would have cost a fortune in a clinic, yet without which he would have died. Claire’s advice was clear, prompt and extreme value for money. Just knowing I can call her again wherever I am without having to find a clinic is great piece of mind".',
    ],
  },
  {
    from: 'Erica, NSW',
    text: [
      '"I had a great experience with VetChat and would highly recommend them! One night recently I found a tick on my puppy’s blanket. I was pretty worried about her, but she seemed fine, so I didn’t know if I should rush her to an emergency Vet or not…',
      'I wasn’t sure what to expect from an online Vet, but it was pretty much just like seeing a Vet in person. I got a response right away, I could easily upload photos of the tick, and Claire’s advice was detailed and accurate (also friendly and reassuring!).',
      'She was able to give me an opinion on the type of tick it was (luckily just a brown dog tick) and tell me signs to watch for just in case. My regular Vet confirmed the tick diagnosis in person when we went a few days later for vaccinations.  Great service, thank you!"',
    ],
  },
];

export const VIDEOS = [
  {
    title: 'Why does my pet eat grass?',
    video: 'https://www.youtube.com/embed/KDlTS9CZ6vA?rel=0',
    thumbnail: 'https://img.youtube.com/vi/KDlTS9CZ6vA/mqdefault.jpg',
  },
  {
    title: "How do i stop my puppy from biting guest's ankle?",
    video: 'https://www.youtube.com/embed/jr0CIsjH17g?rel=0',
    thumbnail: 'https://img.youtube.com/vi/jr0CIsjH17g/mqdefault.jpg',
  },
  {
    title: 'Should I be worried if my dog has eaten a snail?',
    video: 'https://www.youtube.com/embed/FSHZm4HPi5I?rel=0',
    thumbnail: 'https://img.youtube.com/vi/FSHZm4HPi5I/mqdefault.jpg',
  },
];

export const WHY_VISIT_ONLINE = [
  { image: 'checkup-icon-1.png', text: 'For peace of mind, just to check up' },
  {
    image: 'available-24-7.png',
    text: 'Consultations 24 hours a day, every day',
  },
  {
    image: 'qa-icon-1.png',
    text: 'For everyday questions and credible answers',
  },
  {
    image: 'team-icon-1.png',
    text: ' Supportive team of trusted Australian registered Veterinarians',
  },
  {
    image: 'urgency-icon-1.png',
    text: 'For clarity on urgency and next steps',
  },
  {
    image: 'guarantee-icon-1.png',
    text: '100% peace of mind money back guarantee',
  },
];

export const WHY_VISIT_ONLINE_LAST = [
  {
    image: 'available-24-7.png',
    text:
      'We’re here 24 hours a day, daily. So we can help your pet when and where you need us.',
    title: 'Sit and stay',
  },
  {
    image: 'followup-icon-1.png',
    text:
      'We don’t love and leave you, you have a clear plan of what to do and get next-day follow up.',
    title: 'By your side',
  },
  {
    image: 'guarantee-icon-1.png',
    text:
      'Our peace of mind guarantee means you’ll leave us feeling less worried about what to do or we’ll refund your money.',
    title: '100% Guarantee',
  },
];

export const MESSAGE_CLAIRE = {
  image: 'cjvetchat-web.jpg',
  title: 'A quick chat might save a lot',
  message: `Having worked in Vet practices all over Australia, we know that lots of pets can be treated at home. That’s where the idea for VetChat came from.\n
        We saw a need for pre-clinic care to help free up Vets for more serious cases, but also, to better support pet-parents like you.\n    
        When it comes to peace of mind, our online consults give you that instantly. Sometimes, they also confirm you need to visit a Vet, so you always know next steps.\n    
        And while we’re absolutely here for you in moments of worry, we want to be your Vet companion in daily life too.\n
        From choosing a pet right through to senior pet health plans, our Vets are trusted advisors at every stage of your baby’s life cycle.`,
};

export const CONSULTATION_TYPES = [
  {
    type: 'chat',
    title: 'Chat Consult',
    price: 39.95,
    message: ['One (1) chat consultation', 'Vet help within minutes'],
    background: 'rgb(0, 199, 250)',
    link: 'chat-purchase',
  },
  {
    type: 'video',
    title: 'Video Consult',
    price: 39.95,
    message: ['One (1) video consultation', 'Vet help within minutes'],
    background: 'rgb(84, 196, 198)',
    link: 'video-purchase',
  },
  {
    type: 'subscription',
    title: '12 Month Membership',
    price: 199.95,
    message: ['Six (6) video or text chats', 'No lock in contract'],
    background: 'rgb(255, 73, 98)',
    link: 'more',
    extraInfo: (
      <div className='subscribe-message'>
        <p>Includes:</p>
        <ul>
          <li>12-month subscription membership</li>
          <li>Pawsonal health record for your pet</li>
          <li>Six (6) video or text chat consultations</li>
          <li>Real-time support from a team of trusted Aussie Vets</li>
          <li>
            Peace of mind, credible answers, clarity on urgency and next steps
          </li>
          <li>Consults between 6am-midnight Sydney Aus time, every day</li>
          <li>10% off any additional services we offer</li>
          <li>100% PEACE OF MIND GUARANTEE</li>
          <li>Need to run away? Cancel at any time. We won’t lock you in</li>
        </ul>
        <p>This is a no lock-in subscription, so you can cancel at anytime.</p>
        <p>
          Friendly note: Any discounted prices displayed apply to the first
          payment only. Once your 12 months are up, this subscription renews
          automatically at the full price of $199.95. If you’d like to cancel
          your subscription, please do so before this time.
        </p>
        <a className='btn btn-vetchat' href='/subscribe'>
          Get Ongoing Vet Care
        </a>
      </div>
    ),
  },
];

export const PAGES = {
  home: {
    title: 'VetChat- Chat with Vet online',
  },
  'how-it-works': {
    title: 'How it works | VetChat',
    bannerImage: '',
    bannerTitle: '',
  },
  pricing: {
    title: 'Pricing | VetChat',
  },
  about: {
    title: "Let's Vet together | VetChat",
    bannerImage: 'dog-and-cat-blue.jpg',
    bannerTitle: "Let's Vet together",
  },
  'privacy-policy': {
    title: 'Privacy Policy | VetChat',
    bannerImage: '',
    bannerTitle: '',
    content: 'PRIVACY_POLICY',
  },
  'terms-and-conditions': {
    title: 'Terms and Conditions | VetChat',
    bannerImage: '',
    bannerTitle: '',
    content: 'TERMS_CONDITIONS',
  },
  faq: {
    title: 'FAQ | VetChat',
    bannerImage: '',
    bannerTitle: '',
    content: 'FAQ',
  },
  contact: {
    title: 'Contact | VetChat',
    bannerImage: '',
    bannerTitle: '',
  },
  resources: {
    title: 'Resources | VetChat',
    bannerImage: 'HeaderImage_Gradient2-1024x717.jpg',
    bannerTitle: 'Resources',
  },
  videos: {
    title: 'Videos | VetChat',
    bannerImage: 'upside-down-cat.jpg',
    bannerTitle: 'Videos',
  },
  purchase: {
    title: 'Purchase Chat | VetChat',
    bannerImage: '',
    bannerTitle: '',
  },
  giving: {
    title: 'Giving | VetChat',
    banerImage: '',
    bannerTitle: '',
  },
};

export const GENERAL_CONTENT = {
  PRIVACY_POLICY: (
    <Fragment>
      <p>
        This web site (<strong>www.vetchat.com.au</strong>) is owned and
        operated by&nbsp;
        <strong>VETCHAT SERVICES PTY LTD (ACN 616 786 997)</strong>&nbsp;and
        will be referred to as “We”, “our” and “us” in this Privacy Policy (
        <strong>Privacy Policy</strong>). By using this site, you agree to the
        Privacy Policy. The Privacy Policy relates to the collection and use of
        personal information you may supply to us through your conduct on the
        web site.
      </p>
      <p>
        We reserve the right, at our discretion, to modify or remove portions of
        this Privacy Policy at any time. This Privacy Policy is in addition to
        any other terms and conditions applicable to the web site.
      </p>
      <p>
        We recognise the importance of protecting the privacy of information
        collected about visitors to our web site, particularly information that
        is capable of identifying an individual (“personal information”). This
        Privacy Policy governs the manner in which your personal information,
        obtained through the web site, will be dealt with. This Privacy Policy
        should be reviewed periodically so that you are updated on any changes.
        We welcome your comments and feedback.
      </p>
      <p>
        The Service is not intended for children under 18 years of age. No one
        under age 18 may provide any personal information to or through the
        Service. We do not knowingly collect personal information from children
        under 18. If you are under 18, please do not use or provide any
        information to or through the Service.
      </p>
      <p>
        <strong>Personal Information</strong>
      </p>
      <p>
        Personal information about visitors to our site is collected only when
        knowingly and voluntarily submitted. For example, we may need to collect
        such information to provide you with further services or to answer or
        forward any requests or enquiries. It is our intention that this policy
        will protect your personal information from being dealt with in any way
        that is inconsistent with applicable privacy laws in Australia.
      </p>
      <p>
        Personal information collected may include, but not be limited to: name,
        email address, mailing address, phone number, etc. Users may refuse to
        provide personal information, except that such refusal may prevent them
        from utilising some or all of the services provided on the site.
      </p>
      <p>
        <strong>Use of Information</strong>
      </p>
      <p>
        Personal information that visitors submit to our site is used only for
        the purpose for which it is submitted or for such other secondary
        purposes that are related to the primary purpose, unless we disclose
        other uses in this Privacy Policy or at the time of collection.
      </p>
      <p>
        Copies of correspondence sent from the web site, that may contain
        personal information, are stored as archives for record-keeping and
        back-up purposes only.
      </p>
      <p>
        We may from time to time, use your personal information to contact you
        about: important changes to our services, planned service outages, any
        communication necessary for the provision of agreed services to you, and
        promotional materials if you have consented to receiving them.
      </p>
      <p>
        <strong>Collecting information on Registered members</strong>
      </p>
      <p>
        As part of registering with us, we collect personal information about
        you in order for you to take full advantage of our services. To do this
        it may be necessary for you to provide additional information to us as
        detailed below.
      </p>
      <p>
        Registration is completely optional. Registration may include submitting
        your name, email address, address, telephone numbers, and option on
        receiving updates and promotional material and other information. You
        may access this information at any time by logging in and going to your
        account.
      </p>
      <p>
        <strong>Credit Card Details</strong>
      </p>
      <p>
        Credit Card details are not stored by our site, but immediately
        transmitted to the payment gateway in a secure manner acceptable to the
        payment gateway.
      </p>
      <p>
        <strong>Disclosure</strong>
      </p>
      <p>
        Apart from where you have consented or disclosure is necessary to
        achieve the purpose for which it was submitted, personal information may
        be disclosed in special situations where we have reason to believe that
        doing so is necessary to identify, contact or bring legal action against
        anyone damaging, injuring, or interfering (intentionally or
        unintentionally) with our rights or property, users, or anyone else who
        could be harmed by such activities.
      </p>
      <p>
        We may disclose personal information in compliance with a court order,
        co-operation with criminal investigations, other situations in which we
        reasonably believe disclosure is required to comply with laws, and to
        prevent or minimise harm to personal or public safety.
      </p>
      <p>
        We may engage third parties to provide you with goods or services on our
        behalf. In that circumstance, we may disclose your personal information
        to those third parties in order to meet your request for goods or
        services.
      </p>
      <p>
        From time to time, we may need to transfer your information to overseas
        entities that are not subject to same level of privacy protections as
        Australia. Reasons for this may include: backup storage, and provision
        of services by third parties. When this is required, we will notify you
        and obtain consent for this transfer.<strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Data Security</strong>
      </p>
      <p>
        We strive to ensure the security, integrity and privacy of personal
        information submitted to our sites, and we review and update our
        security measures in light of current technologies.
      </p>
      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorised access, use,
        alteration, and disclosure. All information you provide to us is stored
        on our secure servers behind firewalls. Any payment transactions will be
        encrypted. The safety and security of your information also depends on
        you. Where we have given you (or where you have chosen) a password for
        access to certain parts of our Service, you are responsible for keeping
        this password confidential. We ask you not to share your password with
        anyone.
      </p>
      <p>
        Unfortunately, the transmission of information via the internet cannot
        be guaranteed to be completely secure. Although we do our best and take
        all reasonable steps to protect the personal information you transmit to
        us through our online products and services, we cannot guarantee the
        security of your personal information transmitted to or through our
        Service.
      </p>
      <p>
        In addition, our employees and the contractors who provide services
        related to our information systems are obliged to respect the
        confidentiality of any personal information held by us. However, we will
        not be held responsible for events arising from unauthorised access to
        your personal information.
      </p>
      <p>
        Any transmission of personal information is at your own risk. We are not
        responsible for circumvention of any privacy settings or security
        measures contained on the Service. You agree to hold VetChat harmless
        for any damages that may result from such unauthorised access or use.
      </p>
      <p>
        <strong>Links to external websites</strong>
      </p>
      <p>
        Our websites may contain hyperlinks to websites not controlled by us. We
        cannot and do not provide assurances of privacy and information security
        related to such websites.
      </p>
      <p>
        <strong>Collecting Information from Users</strong>
      </p>
      <p>
        <em>IP Addresses</em>
      </p>
      <p>
        Our web servers gather your IP address to assist with the diagnosis of
        problems or support issues with our services. Again, information is
        gathered in aggregate only and cannot be traced to an individual user.
      </p>
      <p>
        <em>Cookies</em>
      </p>
      <p>
        We may use cookies to provide you with a better experience. A cookie is
        a small file placed on the hard drive of your computer. You may refuse
        to accept browser cookies by activating the appropriate setting on your
        browser. However, if you select this setting you may be unable to access
        certain parts of our Website. Unless you have adjusted your browser
        setting so that it will refuse cookies, our system will issue cookies
        when you direct your browser to our Website. These cookies allow us to
        increase your security by storing your session ID and are a way of
        monitoring single user access. This aggregate, non-personal information
        is collated and provided to us to assist in analysing the usage of the
        site. Users may choose to disable cookies, but this may result in loss
        of some or all of the site’s functionality.
      </p>
      <p>
        <em>Non-personal Information</em>
      </p>
      <p>
        Certain non-personal information may also be collected by the site. This
        may include: browser name, the type of computer and technical
        information about a user’s means of connection to our site, such as the
        operating system and the Internet service provider utilised and other
        similar information. This information is used to customise the user’s
        experience of our site, and to assist in managing the site and its
        supporting infrastructure.<strong>&nbsp;</strong>
      </p>
      <p>
        <strong>
          Third-Party Use of Cookies and Other Tracking Technologies
        </strong>
      </p>
      <p>
        Some content or applications, including advertisements, on the Service
        are served by third-parties, including advertisers, ad networks and
        servers, content providers, and application providers. These third
        parties may use cookies alone or in conjunction with web beacons or
        other tracking technologies to collect information about you when you
        use our website. The information they collect may be associated with
        your personal information or they may collect information, including
        personal information, about your online activities over time and across
        different websites and other online services. They may use this
        information to provide you with interest-based (behavioural) advertising
        or other targeted content. We do not control these third parties’
        tracking technologies or how they may be used. If you have any questions
        about an advertisement or other targeted content, you should contact the
        responsible provider directly.
      </p>
      <p>
        <strong>&nbsp;</strong>
        <strong>Access to Information</strong>
      </p>
      <p>
        We will endeavour to take all reasonable steps to keep secure any
        information which we hold about you, and to keep this information
        accurate and up to date. If, at any time, you discover that information
        held about you is incorrect, you may contact us using the details on our
        website, to have the information corrected.
      </p>
      <p>
        Registered users can access their information by logging into the
        website and viewing their account.
      </p>
      <p>
        Our employees and the contractors who provide services related to our
        information systems are obliged to respect the confidentiality of any
        personal information held by us.
      </p>
      <p>
        <strong>Changes to this privacy policy</strong>
      </p>
      <p>
        We have the discretion to update this privacy policy at any time. We
        encourage users to frequently check this page for any changes to stay
        informed about how we are helping to protect the personal information we
        collect. You acknowledge and agree that it is your responsibility to
        review this privacy policy periodically and become aware of
        modifications.
      </p>
      <p>
        <strong>Your acceptance of these terms</strong>
      </p>
      <p>
        By using this site, you signify your acceptance of this policy. If you
        do not agree to this policy, please do not use our site. Your continued
        use of the site following the posting of changes to this policy will be
        deemed your acceptance of those changes.
      </p>
      <p>
        <strong>Problems or questions</strong>
      </p>
      <p>
        If we become aware of any ongoing concerns or problems with our web
        sites, we will take these issues seriously and work to address these
        concerns. If you have any further queries relating to our Privacy
        Policy, or you have a problem or complaint, please contact us by using
        the “Contact Us” feature on our website.
      </p>
      <p>
        For more information about privacy issues in Australia and protecting
        your privacy, visit the Australian Federal Privacy Commissioner’s web
        site: &nbsp;
        <a
          href='https://www.privacy.gov.au/'
          target='_blank'
          rel='noopener noreferrer'
        >
          https://www.privacy.gov.au/
        </a>
        .
      </p>
    </Fragment>
  ),
  TERMS_CONDITIONS: (
    <Fragment>
      <p>
        <strong>1. About the Website</strong>
      </p>
      <p>
        1.1. Welcome to www.vetchat.com.au (the 'Website'). The Website provides
        veterinary services (the ‘Services’).
        <br />
        1.2. The Website is operated by VETCHAT SERVICES PTY LTD (ABN 96 616 786
        997). Access to and use of the Website, or any of its associated
        products or services, is provided by VETCHAT SERVICES PTY LTD. Please
        read these terms and conditions (the 'Terms') carefully. By using,
        browsing and/or reading the Website, this signifies that you have read,
        understood and agree to be bound by the Terms. If you do not agree with
        the Terms, you must cease usage of the Website, or any of Services,
        immediately.
        <br />
        1.3. VETCHAT SERVICES PTY LTD reserves the right to review and change
        any of the Terms by updating this page at its sole discretion. When
        VETCHAT SERVICES PTY LTD updates the Terms, it will use reasonable
        endeavours to provide you with notice of updates to the Terms. Any
        changes to the Terms take immediate effect from the date of their
        publication. Before you continue, we recommend you keep a copy of the
        Terms for your records.
      </p>
      <p>
        <strong>2. Acceptance of the Terms</strong>
      </p>
      <p>
        You accept the Terms by remaining on the Website. You may also accept
        the Terms by clicking to accept or agree to the Terms where this option
        is made available to you by VETCHAT &nbsp;SERVICES PTY LTD in the user
        interface.
      </p>
      <p>
        <strong>3. Veterinary Advice and Supply</strong>
      </p>
      <p>
        3.1. Information presented on our Website is of a general nature and not
        intended to be a substitute for professional veterinary healthcare
        advice.
        <br />
        3.2. Documents and graphics published on our Website may include
        inaccuracies or typographical errors.
        <br />
        3.3. You accept that recommendations as to diagnosis, treatment and best
        standards of practice are constantly evolving in the Veterinary
        industry, and that opinions between professionals may differ from one
        another.
        <br />
        3.4. You agree that any advice provided by our qualified Veterinarians,
        is limited in the absence of hands-on examination of the pet. You accept
        that VETCHAT SERVICES PTY LTD cannot be held liable for any damage or
        loss arising from your reliance on advice derived by use of our Website
        in such circumstances.
        <br />
        3.5. You acknowledge and accept that we will only issue and fulfil
        medication scripts, at our discretion to customers whose identity is
        proved to us. The criteria and method of providing your identity will be
        set out on the Website, at our absolute discretion, and may be dependent
        on the type of medication to be provided.
        <br />
        3.6 You hereby acknowledge and accept the limitations and risks of
        providing information relating to a pet that has not had a physical
        examination.
        <br />
        3.7 The Service is not an emergency service, it is to be used for
        general advice only and is not intended to replace a physical
        examination by a Veterinarian. If you are in need of emergency
        assistance, you should take your pet to the nearest emergency Veterinary
        practice. Such emergency conditions that need immediate hands on care
        and are not suitable for online advice include but are not limited to:
        <br />
        <ul>
          <li>Loss of consciousness</li>
          <li>Difficulty breathing</li>
          <li>Bleeding</li>
          <li>
            Trauma or injury such as from a hit by car, fall from a height etc
          </li>
          <li>Paralysis</li>
          <li>Allergic reaction</li>
          <li>Unable to pass urine</li>
          <li>Snake bite</li>
        </ul>
        <br />
        3.8 You agree that no Veterinarian-Client-Patient-Relationship ("VCPR")
        is established in connection with the Site. You agree that a VCPR is
        only established with a hands-on visit to your Veterinarian.
        <br />
        3.9 You should never disregard medical advice received from your primary
        Veterinarian(s) because of information provided to you by our Vets
        through the service.
        <br />
        3.10 You may not record, by video or audio, in whole or in part, any
        consultation with a Veterinarian.
      </p>
      <p>
        <strong>4. Registration to use the Services</strong>
      </p>
      <p>
        4.1. In order to access the Services, you may be required to register
        for an account through the Website (the 'Account').
        <br />
        4.2. As part of the registration process, or as part of your continued
        use of the Services, you may be required to provide personal information
        about yourself (such as identification or contact details), including:
        <br />
        (a) email address;
        <br />
        (b) name;
        <br />
        (c) postal address;
        <br />
        (d) telephone numbers; and
        <br />
        (e) option on receiving updates, promotional material, and other
        information.
        <br />
        4.3. You warrant that any information you give to VETCHAT SERVICES PTY
        LTD in the course of completing the registration process will always be
        accurate, correct and up to date.
        <br />
        4.4. Once you have completed the registration process, you will be a
        registered member of the Website ('Member') and agree to be bound by the
        Terms.
        <br />
        4.5. You may not use the Services and may not accept the Terms if:
        <br />
        (a) you are not of legal age to form a binding contract with VETCHAT
        SERVICES PTY LTD; or
        <br />
        (b) you suffer from mental impairment which renders you unable to
        understand the Terms at the time of using the Website; or
        <br />
        (c) you are a person barred from receiving the Services under the laws
        of Australia or other countries including the country in which you are
        resident or from which you use the Services.
      </p>
      <p>
        <strong>5. Your obligations as a Member</strong>
      </p>
      <p>
        5.1. As a Member, you agree to comply with the following:
        <br />
        (a) you will use the Services only for purposes that are permitted by:
        <br />
        (i) the Terms; and
        <br />
        (ii) any applicable law, regulation or generally accepted practices or
        guidelines in the relevant jurisdictions;
        <br />
        (b) you have the sole responsibility for protecting the confidentiality
        of your password and/or email address. Use of your password by any other
        person may result in the immediate cancellation of the Services;
        <br />
        (c) any use of your registration information by any other person, or
        third parties, is strictly prohibited. You agree to immediately notify
        VETCHAT SERVICES PTY LTD of any unauthorised use of your password or
        email address or any breach of security of which you have become aware;
        <br />
        (d) access and use of the Website is limited, non-transferable and
        allows for the sole use of the Website by you for the purposes of
        VETCHAT SERVICES PTY LTD providing the Services;
        <br />
        (e) you will not use the Services or the Website in connection with any
        commercial endeavours except those that are specifically endorsed or
        approved by the management of VETCHAT SERVICES PTY LTD;
        <br />
        (f) you will not use the Services or Website for any illegal and/or
        unauthorised use which includes collecting email addresses of Members by
        electronic or other means for the purpose of sending unsolicited email
        or unauthorised framing of or linking to the Website;
        <br />
        (g) you agree that commercial advertisements, affiliate links, and other
        forms of solicitation may be removed from the Website without notice and
        may result in termination of the Services. Appropriate legal action will
        be taken by VETCHAT SERVICES PTY LTD for any illegal or unauthorised use
        of the Website; and
        <br />
        (h) you acknowledge and agree that any automated use of the Website or
        its Services is prohibited.
        <br />
        (i) You may not use the Services to contact our Veterinarians for any
        reason other than to seek information and guidance for your pet in
        accordance with these Terms.
      </p>
      <p>
        <strong>6. Payment</strong>
      </p>
      <p>
        6.1. Where the option is given to you, you may make payment for the
        Services (the 'Services Fee') by way of:
        <br />
        (a) Credit Card Payment ('Credit Card').
        <br />
        6.2. All payments made in the course of your use of the Services are
        made using Stripe ('Stripe'). In using the Website, the Services or when
        making any payment in relation to your use of the Services, you warrant
        that you have read, understood and agree to be bound by the Stripe terms
        and conditions which are available on their website.
        <br />
        6.3. You acknowledge and agree that where a request for the payment of
        the Services Fee is returned or denied, for whatever reason, by your
        financial institution or is unpaid by you for any other reason, then you
        are liable for any costs, including banking fees and charges, associated
        with the Services Fee.
        <br />
        6.4. You agree and acknowledge that VETCHAT SERVICES PTY LTD can vary
        the Services Fee at any time.
      </p>
      <p>
        <strong>7. Refund Policy</strong>
      </p>
      <p>
        VETCHAT SERVICES PTY LTD will only provide you with a refund of the
        Services Fee in the event they are unable to provide the Service due to
        a technical issue caused by VETCHAT SERVICES PTY LTD or if the manager
        of VETCHAT SERVICES PTY LTD makes a decision, at their absolute
        discretion, that it is reasonable to do so under the circumstances (the
        'Refund').
        <br />
        If you believe you are entitled to a refund, email us at{' '}
        <a href='mailto:service@vetchat.com.au'>service@vetchat.com.au</a> as
        soon as possible.
      </p>
      <p>
        <strong>8. Copyright and Intellectual Property</strong>
      </p>
      <p>
        8.1. The Website, the Services and all of the related products of
        VETCHAT SERVICES PTY LTD are subject to copyright. The material on the
        Website is protected by copyright under the laws of Australia and
        through international treaties. Unless otherwise indicated, all rights
        (including copyright) in the Services and compilation of the Website
        (including but not limited to text, graphics, logos, button icons, video
        images, audio clips, Website, code, scripts, design elements and
        interactive features) or the Services are owned or controlled for these
        purposes, and are reserved by VETCHAT SERVICES PTY LTD or its
        contributors.
        <br />
        8.2. All trademarks, service marks and trade names are owned, registered
        and/or licensed by VETCHAT SERVICES PTY LTD, who grants to you a
        worldwide, non-exclusive, royalty-free, revocable license whilst you are
        a Member to:
        <br />
        (a) use the Website pursuant to the Terms;
        <br />
        (b) copy and store the Website and the material contained in the Website
        in your device’s cache memory; and
        <br />
        (c) print pages from the Website for your own personal and
        non-commercial use. VETCHAT SERVICES PTY LTD does not grant you any
        other rights whatsoever in relation to the Website or the Services. All
        other rights are expressly reserved by VETCHAT SERVICES PTY LTD.
        <br />
        8.3. VETCHAT SERVICES PTY LTD retains all rights, title and interest in
        and to the Website and all related Services. Nothing you do on or in
        relation to the Website will transfer any:
        <br />
        (a) business name, trading name, domain name, trade mark, industrial
        design, patent, registered design or copyright, or
        <br />
        (b) a right to use or exploit a business name, trading name, domain
        name, trade mark or industrial design, or
        <br />
        (c) a thing, system or process that is the subject of a patent,
        registered design or copyright (or an adaptation or modification of such
        a thing, system or process), to you.
        <br />
        8.4. You may not, without the prior written permission of VETCHAT
        SERVICES PTY LTD and the permission of any other relevant rights owners:
        broadcast, republish, up-load to a third party, transmit, post,
        distribute, show or play in public, adapt or change in any way the
        Services or third party Services for any purpose, unless otherwise
        provided by these Terms. This prohibition does not extend to materials
        on the Website which are freely available for re-use or are in the
        public domain.
      </p>
      <p>
        <strong>9. Privacy</strong>
      </p>
      <p>
        9.1. VETCHAT SERVICES PTY LTD takes your privacy seriously and any
        information provided through your use of the Website and/or Services are
        subject to VETCHAT SERVICES PTY LTD’s Privacy Policy, which is available
        on the Website.
        <br />
        9.2 Our Veterinarians may keep notes and copies of the information that
        you provide and any information, guidance or advice they give through
        the Services, and you agree that they may use that information for any
        internal professional purposes as may be required by law or relevant
        regulation (such as for supervisory purposes).
        <br />
        9.3 Our Veterinarians are bound by a contractual obligation with VETCHAT
        SERVICES PTY LTD to keep your personal information safe, secure and
        confidential.
        <br />
        9.4 You hereby consent to VETCHAT SERVICES PTY LTD obtaining your pet’s
        medical records from the person(s) you indicate as your primary
        veterinarian(s) and for VETCHAT SERVICES PTY LTD to provide the
        information to the primary veterinarian relating to your usage of the
        Website and Services. You authorise copies or summaries of the medical
        records and images pertaining to your pets to be released to the Company
        by fax, mail or by e-mail. You hereby consent to the Company’s storage
        of Your pet’s medical records in the Company’s system so that they can
        be reviewed by Veterinarian(s) you speak with or distributed to
        emergency or specialist referral hospitals at your request in the event
        that your pet might need emergency care at a time when your primary
        veterinarian(s) might not available to care for Your pet.
      </p>
      <p>
        <strong>10. General Disclaimer</strong>
      </p>
      <p>
        10.1. Nothing in the Terms limits or excludes any guarantees,
        warranties, representations or conditions implied or imposed by law,
        including the Australian Consumer Law (or any liability under them)
        which by law may not be limited or excluded.
        <br />
        10.2. Subject to this clause, and to the extent permitted by law:
        <br />
        (a) all terms, guarantees, warranties, representations or conditions
        which are not expressly stated in the Terms are excluded; and
        <br />
        (b) VETCHAT SERVICES PTY LTD will not be liable for any special,
        indirect or consequential loss or damage (unless such loss or damage is
        reasonably foreseeable resulting from our failure to meet an applicable
        Consumer Guarantee), loss of profit or opportunity, or damage to
        goodwill arising out of or in connection with the Services or these
        Terms (including as a result of not being able to use the Services or
        the late supply of the Services), whether at common law, under contract,
        tort (including negligence), in equity, pursuant to statute or
        otherwise.
        <br />
        10.3. Use of the Website and the Services is at your own risk.
        Everything on the Website and the Services is provided to you “as is”
        and “as available” without warranty or condition of any kind. None of
        the affiliates, directors, officers, employees, agents, contributors and
        licensors of VETCHAT SERVICES PTY LTD make any express or implied
        representation or warranty about the Services or any products or
        Services (including the products or Services of VETCHAT SERVICES PTY
        LTD) referred to on the Website. This includes (but is not restricted
        to) loss or damage you might suffer as a result of any of the following:
        <br />
        (a) failure of performance, error, omission, interruption, deletion,
        defect, failure to correct defects, delay in operation or transmission,
        computer virus or other harmful component, loss of data, communication
        line failure, unlawful third party conduct, or theft, destruction,
        alteration or unauthorised access to records;
        <br />
        (b) the accuracy, suitability or currency of any information on the
        Website, the Services, or any of its Services related products
        (including third party material and advertisements on the Website);
        <br />
        (c) costs incurred as a result of you using the Website, the Services or
        any of the products of VETCHAT SERVICES PTY LTD; and
        <br />
        (d) the Services or operation in respect to links which are provided for
        your convenience.
        <br />
        10.4. In relation to the use of the words “on demand”, VETCHAT SERVICES
        PTY LTD makes no guarantee or representation that Services are provided
        immediately upon request or within any determined time frame. Time
        frames for access to our Services are subject to availability and may be
        displayed on the Website from time to time.
        <br />
        10.5 Access to the Website and Service may be suspended temporarily and
        without notice in the case of system failure, maintenance or repair or
        for reasons beyond our control.
        <br />
        10.6 The availability of the Service is dependent on the availability of
        a Veterinarian at the time you submit your Request. If no Veterinarian
        is available at that time, we will do what we can to get the next
        available Veterinarian to contact you as soon as possible using the
        details you provide, to try to provide a consultation.
      </p>
      <p>
        <strong>11. Limitation of liability</strong>
      </p>
      <p>
        11.1. VETCHAT &nbsp;SERVICES PTY LTD’s total liability arising out of or
        in connection with the Services or these Terms, however arising,
        including under contract, tort (including negligence), in equity, under
        statute or otherwise, will not exceed the resupply of the Services to
        you.
        <br />
        11.2. You expressly understand and agree that VETCHAT SERVICES PTY LTD,
        its affiliates, employees, agents, contributors and licensors shall not
        be liable to you for any direct, indirect, incidental, special
        consequential or exemplary damages which may be incurred by you, however
        caused and under any theory of liability. This shall include, but is not
        limited to, any loss of profit (whether incurred directly or
        indirectly), any loss of goodwill or business reputation and any other
        intangible loss.
        <br />
        11.3. You agree that VETCHAT SERVICES PTY LTD will not be liable for any
        information and services provided via the Website or as a result of an
        enquiry submitted via the Website.
      </p>
      <p>
        <strong>12. Termination of Contract</strong>
      </p>
      <p>
        12.1. The Terms will continue to apply until terminated by either you or
        by VETCHAT PTY LTD as set out below.
        <br />
        12.2. If you want to terminate the Terms, you may do so by:
        <br />
        (a) providing VETCHAT SERVICES PTY LTD with 5 days’ notice of your
        intention to terminate; and
        <br />
        (b) closing your accounts for all of the services which you use, where
        VETCHAT &nbsp;SERVICES PTY LTD has made this option available to you.
        Your notice should be sent, in writing, to VETCHAT &nbsp;SERVICES PTY
        LTD via the ‘Contact Us’ link on our homepage.
        <br />
        12.3. VETCHAT SERVICES PTY LTD may at any time, terminate the Terms with
        you if:
        <br />
        (a) you have breached any provision of the Terms or intend to breach any
        provision;
        <br />
        (b) VETCHAT &nbsp;SERVICES PTY LTD is required to do so by law;
        <br />
        (c) the provision of the Services to you by VETCHAT &nbsp;SERVICES PTY
        LTD is, in the opinion of VETCHAT &nbsp;SERVICES PTY LTD, no longer
        commercially viable.
        <br />
        12.4. Subject to local applicable laws, VETCHAT SERVICES PTY LTD
        reserves the right to discontinue or cancel your membership at any time
        and may suspend or deny, in its sole discretion, your access to all or
        any portion of the Website or the Services without notice if you breach
        any provision of the Terms or any applicable law or if your conduct
        impacts VETCHAT SERVICES PTY LTD’s name or reputation or violates the
        rights of those of another party.
      </p>
      <p>
        <strong>13. Indemnity</strong>
      </p>
      <p>
        13.1. You agree to indemnify VETCHAT SERVICES PTY LTD, its affiliates,
        employees, agents, contributors, third party content providers and
        licensors from and against:
        <br />
        (a) all actions, suits, claims, demands, liabilities, costs, expenses,
        loss and damage (including legal fees on a full indemnity basis)
        incurred, suffered or arising out of or in connection with Your Content;
        <br />
        (b) any direct or indirect consequences of you accessing, using or
        transacting on the Website or attempts to do so; and/or
        <br />
        (c) any breach of the Terms.
      </p>
      <p>
        <strong>14. Dispute Resolution</strong>
      </p>
      <p>
        14.1. Compulsory: If a dispute arises out of or relates to the Terms,
        either party may not commence any Tribunal or Court proceedings in
        relation to the dispute, unless the following clauses have been complied
        with (except where urgent interlocutory relief is sort).
        <br />
        14.2. Notice: A party to the Terms claiming a dispute (‘Dispute’) has
        arisen under the Terms, must give written notice to the other party
        detailing the nature of the dispute, the desired outcome and the action
        required to settle the Dispute.
        <br />
        14.3. Resolution: On receipt of that notice (‘Notice’) by that other
        party, the parties to the Terms (‘Parties’) must:
        <br />
        (a) Within 14 days of the Notice endeavour in good faith to resolve the
        Dispute expeditiously by negotiation or such other means upon which they
        may mutually agree;
        <br />
        (b) If for any reason whatsoever, 14 days after the date of the Notice,
        the Dispute has not been resolved, the Parties must either agree upon
        selection of a mediator or request that an appropriate mediator be
        appointed by the President of the Mediation Australia or his or her
        nominee;
        <br />
        (c) The Parties are equally liable for the fees and reasonable expenses
        of a mediator and the cost of the venue of the mediation and without
        limiting the foregoing undertake to pay any amounts requested by the
        mediator as a pre-condition to the mediation commencing. The Parties
        must each pay their own costs associated with the mediation;
        <br />
        (d) The mediation will be held in New South Wales, Australia.
        <br />
        14.4. Confidential All communications concerning negotiations made by
        the Parties arising out of and in connection with this dispute
        resolution clause are confidential and to the extent possible, must be
        treated as “without prejudice” negotiations for the purpose of
        applicable laws of evidence.
        <br />
        14.5. Termination of Mediation: If 3 months have elapsed after the start
        of a mediation of the Dispute and the Dispute has not been resolved,
        either Party may ask the mediator to terminate the mediation and the
        mediator must do so.
      </p>
      <p>
        <strong>15. Venue and Jurisdiction </strong>
      </p>
      <p>
        The Services offered by VETCHAT SERVICES PTY LTD is intended to be
        viewed by residents of Australia. In the event of any dispute arising
        out of or in relation to the Website, you agree that the exclusive venue
        for resolving any dispute shall be in the courts of New South Wales,
        Australia.
      </p>
      <p>
        <strong>16. Governing Law </strong>
      </p>
      <p>
        The Terms are governed by the laws of New South Wales, Australia. Any
        dispute, controversy, proceeding or claim of whatever nature arising out
        of or in any way relating to the Terms and the rights created hereby
        shall be governed, interpreted and construed by, under and pursuant to
        the laws of New South Wales, Australia, without reference to conflict of
        law principles, notwithstanding mandatory rules. The validity of this
        governing law clause is not contested. The Terms shall be binding to the
        benefit of the parties hereto and their successors and assigns.
      </p>
      <p>
        <strong>17. Independent Legal Advice</strong>
      </p>
      <p>
        17.1. It is recommended that you seek your own legal advice regarding
        these Terms.
        <br />
        17.2. You acknowledge that you are aware of your rights to obtain
        independent legal advice, and regardless of whether you have exercised
        this right, you confirm and declare that you understand these Terms,
        agree that the provisions of the Terms are fair and reasonable, and that
        the Terms are not against public policy on the grounds of inequality or
        bargaining power or general grounds of restraint of trade.
      </p>
      <p>
        <strong>18. Severance </strong>
      </p>
      <p>
        If any part of these Terms is found to be void or unenforceable by a
        Court of competent jurisdiction, that part shall be severed and the rest
        of the Terms shall remain in force.
      </p>
      <p>
        <strong>19. PARTNER SCHEMES</strong>
      </p>
      <p>
        19.1. You may also have access to the Services through a Partner
        organisation of VETCHAT SERVICES PTY LTD, such as through your pet
        insurance policy with your insurer, or with retail organisations.
        <br />
        19.2. Where you are receiving the Services through a Partner of VetChat,
        your right to use the Service is also subject to the rules of the
        Partner.
      </p>
      <p>
        <strong>20. VETCHAT MEMBERSHIP SUBSCRIPTION</strong>
      </p>
      <p>
        20.1. To use the VetChat service you must have Internet access and an
        appropriate device. <br />
        20.2. We may offer a number of subscription plans, including special
        promotional subscriptions or plans offered by third parties in
        conjunction with the provision of their own products and services. Some
        subscription plans may have differing conditions and limitations, which
        will be disclosed at your sign-up or in other communications made
        available to you.
        <br />
        20.3. Each Account is for use by a single user only. You must not share
        your username and password with any other person.
        <br />
        20.4. By registering an Account with us, you agree that you are happy to
        receive communications from us by email in relation to your Account or
        use of the Services.
        <br />
        20.5. We may communicate with you by telephone or by email (using the
        details you provide to us in your My Account) or a notice on our
        Website, and you agree that any such email, notice or message will be
        deemed communication “in writing” for legal purposes.
        <br />
        20.6. You agree that we may pass on your Account Details and the
        information you provide in any request, or the medical notes from your
        VetChat Veterinarian to your Vet clinic.{' '}
      </p>
      <p>
        <strong>21. CONTACT</strong>
      </p>
      <p>
        If you have any questions or concerns about the Services or about these
        Terms, please send us a message via our contact form on the Website or
        by email to 
        <a href='mailto:service@vetchat.com.au'>service@vetchat.com.au</a> 
      </p>
    </Fragment>
  ),
  FAQ: (
    <Fragment>
      <p>
        <strong>What's an online consult?</strong>
      </p>
      <p>
        Our online consults make it easy to chat with a Vet on your phone or
        device via a chat or video call. We pride ourselves on delivering three
        things: expert advice, actionable treatment and ongoing care.
      </p>
      <p>
        <strong>Are VetChat Vets legit?</strong>
      </p>
      <p>
        YES! All our Vets are board-certified and born animal lovers. We’ve all
        worked in clinics for 5+ years. The only difference is now we're online,
        where you need us––because we're doting pet carers too. Get to know our
        Vets better{' '}
        <a href='/about' target='_blank'>
          here
        </a>
        .
      </p>
      <p>
        <strong>What are the benefits of using VetChat?</strong>
      </p>
      <p>
        We’re here when your Vet can't be: 24 hours a day, 7 days a week. And
        that's only the beginning.
      </p>
      <ul>
        <li>
          We take the stress out of taking them out with real Vet advice from
          the comfort of your couch
        </li>
        <li>
          Our advice isn't just personalised, it’s credible (Dr Google is not
          your fur baby’s best friend)
        </li>
        <li>
          We’re here on screen the moment you need us, so you can be proactive
          and act early
        </li>
        <li>
          We empower you with know-how and give you the confidence to care for
          your pet
        </li>
        <li>
          Instead of waiting to see if it gets worse and spending more in the
          long run, we can save you money (and sanity) now
        </li>
      </ul>
      <p>
        <strong>Can we use VetChat instead of going to the Vet?</strong>
      </p>
      <p>
        VetChat is here to help you make the right decision for your pet -
        whether that be you can manage at home, or need hands-on care - and
        advice as to the urgency of that - whether you can wait for your clinic
        to open, or need emergency treatment now. VetChat is not a replacement
        for hands-on care.
      </p>
      <p>Using VetChat is a win-win for instant peace of mind.</p>
      <p>
        <strong>What symptoms can your Vets help us with?</strong>
      </p>
      <p>
        It might be an ongoing condition. Or maybe it's new and completely out
        of character. As soon as you notice something's up, we can help your pet
        with real Vet advice, so you know what to do next.
      </p>
      <ul>
        <li>Already diagnosed conditions</li>
        <li>Allergies and itchy skin</li>
        <li>Unexplained vomiting or diarrhoea</li>
        <li>Coughing, sneezing or conjunctivitis</li>
        <li>Sore paw pads or broken nails</li>
        <li>Minor cuts or infections</li>
        <li>Changes in behaviour</li>
        <li>Gums, teeth and smelly breath</li>
        <li>Bad smells in general!</li>
        <li>Barking or behavioural problems</li>
        <li>Putting on weight (or losing it)</li>
        <li>Drinking lots of water (or not enough)</li>
        <li>Anything that's worrying you</li>
      </ul>
      <p>
        For serious emergencies, your pet needs urgent hands-on attention.
        Please call your local or emergency Vet straight away.
      </p>
      <p>
        <strong>I think this is an emergency. HELP!</strong>
      </p>
      <p>
        Please go straight to your local Vet or emergency centre for any of the
        following:
      </p>
      <ul>
        <li>
          Traumatic incidents (hit by car, fallen from height, dog attacks, cat
          fights)
        </li>
        <li>Breathing difficulties</li>
        <li>Unable to walk normally</li>
        <li>Recurring seizures</li>
        <li>Dogs or cats producing only a small amount or no urine</li>
        <li>Severe abdomen pain (swollen or bloated)</li>
      </ul>
      <p>
        We recommend urgent hands-on attention for your pet in all the above
        cases. And we're sending you positive thoughts for a speedy recovery in
        the meantime.
      </p>
      <p>
        <strong>What do I need to have a VetChat consultation?</strong>
      </p>
      <p>
        Our video takes place in your browser - there is no download required.
      </p>
      <p>
        We do need to access your camera and microphone to run the consultation.
        Please make sure that you click <b>allow</b> when your phone or computer
        asks you for permission.
      </p>
      <p>To access per device:</p>
      <ul>
        <li>
          Computer (laptop or desktop), please use Google Chrome (Firefox.
          Microsoft Edge also work).
        </li>
        <li>iPad or iPhone: please use the Safari only</li>
        <li>Android: please use the Google Chrome Browser</li>
      </ul>
      <p>
        Note that good internet speed is essential for video. Make sure your
        device is plugged in - video can drain battery!
      </p>
      <p>
        <strong>
          What if I can't upload a photo or video to the chat service?
        </strong>
      </p>
      <p>VetChat supports the following types of uploads:</p>
      <ul>
        <li>Photo: jpeg, png, heic</li>
        <li>Video: .mp4, .mov</li>
      </ul>
      <p>
        If you're having any touble with uploading to your text chat, check a
        couple of things - the main one is if you're having a network problem.
        Poor internet causes loading problems for uploads, especially for
        loading files that are a large size. Another thing to check is whether
        your phone is running out of storage
      </p>
      <p>
        If you are still experiencing issues during a chat your Vet will guide
        you online.
      </p>
      <p>
        <strong>
          what if I'm having trouble connecting to the video service?
        </strong>
      </p>
      <p>
        If you're having any trouble with video, the main culprit is that you're
        having a network problem. Poor internet causes loading problems for
        video. Also make sure that you click allow when your phone or computer
        asks you for permission to use the Video and Microphone.
      </p>
      <p>Please close all other open tabs to improve video call quality.</p>
      <p>
        If you are still experiencing issues after booking a video, your Vet
        will call you on the supplied number and go from there.
      </p>
      <p>
        <strong>How long does a chat session or video call last?</strong>
      </p>
      <p>
        On average our video calls and chat rooms are 20 minutes duration. You
        get follow-up 24 hours afterwards. We'll email you a pawsonal summary
        once we're done too.
      </p>
      <p>
        <strong>Will it ever end up costing more than you say?</strong>
      </p>
      <p>
        Nope. Never. Not even when pigs fly. Our consultations are capped at
        $39.95 6am-midnight AEST/AEDT, and $59.95 midnight-6am AEST/AEDT and
        that's exactly what you'll pay.
      </p>
      <p>
        <strong>If I want to start chatting, how quickly do you reply?</strong>
      </p>
      <p>We’ll be with you in minutes.</p>
      <p>
        In busy periods (e.g. before and after work) the wait time may be
        longer, but we'll always be with you within 15 minutes.
      </p>
      <p>
        -— For serious emergencies, your fur baby needs urgent hands-on
        attention. Please call your local or emergency Vet straight away.
      </p>
      <p>
        <strong>If it's all online, how do you examine my pet?</strong>
      </p>
      <p>
        That's where you come in. We can't reach out and touch your pet, so we
        need to ask you questions to get the history we need, use our eyes, and
        will ask your help to assess some markets to asess how they are doing.
      </p>
      <p>
        We check things like the refill of gums, breathing rate, behaviour and
        demeanour. If we're on a video call, we get you to show us things in
        real time (even offending poop!). Or over chat, we get you to upload
        photos and videos as we need them.
      </p>
      <p>
        We guide you carefully and closely so you're involved in every single
        part of your pet's care. And so you should be.
      </p>
      <p>
        <strong>Do you follow up afterwards too?</strong>
      </p>
      <p>
        Always. We check in with you and your fur baby 24 hours after every
        consult to make sure you're getting back on track.
      </p>
      <p>
        We also email you a pawsonal summary––either a full transcript of our
        chat, or notes from our video call––to keep for your records. This
        includes a clear and actionable treatment plan, so you know exactly what
        your next steps are. (If seeing your local Vet is part of your treatment
        plan, this is handy to take with you when you visit.)
      </p>
      <p>
        <strong>What kinds of pets do you look after?</strong>
      </p>
      <p>
        We love and look after all your neighbourhood pets: dogs, cats, bunnies,
        birds, mice, rats––we don't discriminate. From teeny tiny babies to
        beloved golden oldies, we're here to help your pets of all ages.
      </p>
      <p>
        <strong>Do you prescribe medication if we need it?</strong>
      </p>
      <p>
        At this point in time, we can't prescribe medication. VetChat is your
        first port of call for your pet. We're your Veterinary advisory service,
        in many cases, your pet will be able to be treated and monitored at home
        without it. We will certainly advise if it's needed, and this is
        something you'll need to visit your local Vet for.
      </p>
      <p>
        <strong>What about payment: is it secure and easy?</strong>
      </p>
      <p>
        Of course. All credit card transactions are processed securely by Stripe
        checkout. They have the most stringent level of PCI certification
        available, so your details are never stored by us. You can read more
        about them{' '}
        <a
          href='https://stripe.com/docs/security'
          rel='noopener noreferrer'
          target='_blank'
        >
          here
        </a>
        .
      </p>
      <p>
        <strong>How does it work with my pet insurance?</strong>
      </p>
      <p>
        Usually, the low cost of a VetChat chat or video (when no further
        intervention is required) means that it falls under your excess amount.
        So it’s best to check your excess and what conditions are covered.
      </p>
      <p>
        <strong>What's your Peace of mind guarantee?</strong>
      </p>
      <p>
        We know that going online for Vet help is completely new and not what
        we're used to. So we hope that every single VetChat experience leaves
        you feeling content and relieved as you know what you need to do for
        your pet ––in fact, we guarantee it! And if it doesn't, you can
        absolutely have your money back. Email us at{' '}
        <a href='mailto:service@vetchat.com.au'>service@vetchat.com.au</a>
      </p>
    </Fragment>
  ),
  About: {
    /* vetchat: [ `When our fur babies are sick, we’re often left guessing instead of acting confidently. Nine times out of ten, we also expect to wait: for the Vet, for time off, for things to get better. But what if you could chat to a Vet in those early moments when you’re feeling unsure?`,
        `That’s exactly why we exist: to make trusted, credible Vet care easier to access for all pets and parents, as soon as you need it.`,
        `Nobody knows your fur baby better than you do, so we examine and monitor your pup in all their favourite places – wherever you are. We listen closely while you chat, then work together to get to the bottom of what’s happening while they snuggle by your side.`,
        `Our qualified online Vets are pet-parents too, so our advice is always clear and actionable. We pass it on so you can take the lead, but we’ll stay by your side as long as you need us.`,
        `We want to be truly timely and helpful: the Vet you always chat with first. Because by helping our fur babies much earlier together, we can all do more for our pets than ever before.`], */
    vetchat: [
      <b>"I don’t know if it’s serious or not."</b>,
      `For most pet-carers, the knowledge gap is huge. Some speculate and wait; others panic and act too soon. But regardless of how quick or complacent we are, we’re all conditioned to go elsewhere. We ask friends and check social media. We consult Dr Google and wait days, even weeks, to see a Vet. But what if instead of scrolling on your phone, you could chat with a Vet right there on your screen?`,
      <b>"A quick chat might save a lot."</b>,
      `Our small group of online Vets are here to be there in your world and to work with your local Vet too. We know that even the littlest sign of sickness can feel like an emergency, which is why we help you diffuse the situation sooner. We listen closely while you chat, then work together with you to get to the bottom of what’s happening using photos and videos. We turn Vet consultations into real conversations and clear, actionable care.`,
      `This is exactly why we exist: to make trusted, credible Vet care easier to access for all pets and carers, as soon as you need it.`,
    ],
    ourValues: [
      {
        title: 'Fur babies and pet-carers come first.',
        info:
          'We have the utmost respect for our pet carers and are committed to doing what’s best for your pets. It’s why we created VetChat in the first place. We take the stress out of not knowing what decision to make for your pet, and provide easy access to trusted answers, so you always know what to do.',
        personality: 'We are … Loving, upbeat, always by your side.',
        petPersonality: 'We are … A loyal Kelpie.',
      },
      {
        title: 'Happy pets, happy Vets.',
        info: (
          <>
            <span style={{ display: 'block', marginBottom: 8 }}>
              We saw the need for pre-clinic Vet care first hand – not just
              after hours, but all hours. Because by helping our pets in those
              early, potentially critical moments, we can now do more for our
              pets than ever before.
            </span>
            <span style={{ display: 'block' }}>
              So we help the pups and old ducks that can be treated at home to
              improve the experience for Vets and pet-carers alike.
            </span>
          </>
        ),
        personality: 'We are … Friendly, collaborative, accepting of everyone.',
        petPersonality: 'We are … An agile Abyssinian.',
      },
      {
        title: 'Walking together is way more fun.',
        info:
          'Here at VetChat, we make flexibility a priority: not just at work, but by fitting into our pet-carers’ lives. We play together as a team, collaborate to solve problems and stay connected, even though we’re remote. And speaking of walks, we’re up for those too – as well as anything that makes your life healthier and happier.',
        personality: 'We are … Friendly, collaborative, accepting of everyone.',
        petPersonality: 'We are … A smiley Golden Retriever.',
      },
      {
        title: 'Real conversations aren’t clinical.',
        info:
          'We may be real vets, but we’re also real people. So we keep clinical speak to a minimum in order to be truly helpful. We listen intently and explain things in ways that our pet-carers easily understand. Conversations create closeness and allow for mutual understanding. Which is why we’re open and honest with each other, always.',
        personality: 'We are … Honest, authentic, genuinely helpful.',
        petPersonality: 'We are … A purring, cuddly kitty.',
      },
      {
        title: 'Everyone can be taught new tricks.',
        info:
          'With the right kind of guidance, anything is possible. We help pet-carers with our Veterinary expertise, but their input is equally invaluable to us. We lead them through the process, enable them with know-how and support them with a detailed plan of attack. But by listening and learning from one another, we can do more for our pets than ever before.',
        personality: 'We are … Guiding, watchful, always alert.',
        petPersonality: 'We are … A clever Border Collie.',
      },
    ], //all our values
    ourVets: [
      {
        name: 'Dr. Claire Jenkins',
        degree: 'VetChat founder & CEO, BVSc (Hons), MANZCVS (SAM)',
        about: [
          `Claire has spent the past 15 years caring for animals as a Small Animal Veterinarian in the UK, Melbourne and is now based in Sydney. She is passionate about early intervention and how easy access to the right advice drives better health outcomes for pets.`,
          `Enter VetChat – providing fast, affordable, expert advice and real-time veterinary consultations online, so pet carers can speak directly to a qualified Vet from wherever they are.`,
          `Founded in 2016, Claire has pulled together a team of Vets she has the greatest respect for. Their shared focus is early intervention, actionable answers and peace of mind right away.`,
          `Claire’s a life-long animal lover, passionate problem solver and adorer of her gorgeous Kelpie ‘Red’.`,
        ],
        image: 'Dr_Claire.jpg',
      },
      {
        name: 'Dr. Elizabeth Shaw',
        degree: 'BVSc (Hons), Small Animal Veterinarian',
        about: [
          `Based in sunny Brisbane, Liz has been working as a small animal Veterinarian since graduating with honours from the University of Queensland in 2012.`,
          `She has a special interest in small animal surgery and is currently studying for her membership exams to the Australian and New Zealand College of Veterinary Scientists.`,
          `Liz is married with two fur-babies, Maisy and Niko, both rescued Ragdoll cats. She enjoys helping people and their pets and loves seeing the close bond between them.`,
        ],
        image: 'dr_liz.jpg',
      },
      {
        name: 'Dr. Lucy Crane',
        degree: 'BVetMed, Small Animal Veterinarian',
        about: [
          `Lucy is our Sunshine Coast - based Vet. She has nine years of experience as both a Mixed and Small Animal Veterinarian and graduated from the Royal Veterinary College in the UK, in 2009, moving here shortly afterwards.`,
          `She's passionate about preventative health, donkey + horse rescue and rehabilitation, neighbourhood pet imaging (radiography and ultrasonography) and reproduction.`,
          `Lucy supports her local community as well as our online one and is kept more than busy with two little humans as well.`,
        ],
        image: 'Dr_Lucy.jpg',
      },
      {
        name: 'Dr. Judith Smith',
        degree: 'BVMS, Small Animal Veterinarian',
        about: [
          `Jude is now Sydney-based but actually started her Veterinary career back in Scotland when she graduated in 2002. Moving here in 2007 means she’s had the pleasure of helping and caring for pets all over the world over the last 17 years.`,
          `She's passionate about providing access to the right advice for those pet carers who cannot immediately access Veterinary care in person, to improve pet-health outcomes.`,
          `Lovely Sawyer (her 12 yo Domestic Short Hair kitty) and Spot (her assumed to be 8 yo Rescue Jack Russell cross) share, and quite possibly, run the home!`,
        ],
        image: 'Dr_Jude.jpg',
      },
      {
        name: 'Dr. Rebecca Dowling',
        degree: 'BVSc, MIxed Animal Veterinarian',
        about: [
          `Bec is Brisbane-based and has been working as a Large and Small Animal Veterinarian since graduating from the University of Queensland in 2004.`,
          `Her love of animals started with her first pets (her black and white pair: Blackie (cat), Snowy (dog); and beloved horse Stook), and her determination to be a Veterinarian never waned from the ripe old age of eight.`,
          `Bec is passionate about helping pet carers understand their pets and know how to keep them happy and healthy!`,
        ],
        image: 'Dr_Bec.jpg',
      },
      {
        name: 'Dr. Jessica Spirou',
        degree: 'BVSc, Small Animal Veterinarian',
        about: [
          `Jess has eight years of experience as a Small Animal Veterinarian in home-town Adelaide since graduating from the University of Queensland in 2011.`,
          `She’s passionate about increasing client education to reduce fear, anxiety and stress for pets and recently completed her Fear Free Veterinary Certificate.`,
          `Frankie (her Diabetic 9 yo Domestic Short Hair Kitty) and Tim Tam (her 16 yo Turtle) are her adored pet family, in excellent hands whilst she’s currently enjoying Toronto, in between consults.`,
        ],
        image: 'Dr_Jess.jpg',
      },
      {
        name: 'Dr. Fiona Turner',
        degree: 'BVSc, Small Animal Veterinarian',
        about: [
          `Fiona is a small animal Veterinarian who works in Melbourne. She’s grown up in the Vet world since the age of three, when she’d accompany her dad (he’s a Vet too) on his visits to horse patients.`,
          `Fi’s passionate about strengthening the bond between humans and animals. She’s also a blogger, producing online veterinary content to help equip pet owners with the information they need.`,
          `Fi has three children; one fur baby, Lenno (the Cavalier King Charles Spaniel cross who snores more loudly than her husband) and two little human kids who keep her very busy.`,
        ],
        image: 'Dr_Fi.jpg',
      },
      {
        name: 'Dr. Kelly Yeo',
        degree: 'BVSc, Small Animal Veterinarian',
        about: [
          `Kelly has ten years of experience as a Small Animal Veterinarian in Melbourne since graduating from the University of Melbourne in 2009.`,
          `Kelly has a special interest in geriatric pet care and improving the quality of life for all pets with chronic illnesses.`,
          `Kelly is mum to a Papillon who is queen of the house, and a Mini Schnauzer who doesn’t let a crumb go to waste!`,
        ],
        image: 'Dr_Kelly.jpg',
      },
    ],
  },
};

export const RESOURCES = {
  title: 'Pet Wellness and Health Resources',
  description:
    'Over the years as Veterinary surgeons we’ve amassed a wealth of knowledge on pets. We’d love to share this with you through the resources below.',
  ebooks: [
    {
      title: 'Vetchat’s guide to 10 things your kitten needs from you',
      slug: 'vetchats-guide-to-10-things-your-kitten-needs-from-you',
      featuredImage:
        '04.-Ebook_10_things_your_new_kitten_needs_from_you_Page_01-04.png',
      file: '04.-Ebook_10_things_your_new_kitten_needs_from_you.pdf',
      fileImage:
        '04.-Ebook_10_things_your_new_kitten_needs_from_you_Page_01.png',
      description: `<p>We know this is a common time for pet carers to seek online Vet help, which is why VetChat is coming to the rescue.</p>
            <p>Our Vets have put together ‘10 things your new kitten needs from you’ to give you the safest and happiest start together.</p>
            <p><strong>Features:</strong></p>
            <ul>
            <li>10 useful tips to take care of your new kitten</li>
            <li>Kitten daily essentials</li>
            <li>Vet certified advice</li>
            </ul>`,
    },
    {
      title: 'Vetchat’s guide to 10 things your puppy needs from you',
      slug: 'vetchats-guide-to-10-things-your-puppy-needs-from-you',
      featuredImage: '03.-Ebook_10_things_puppy-03.png',
      file: '03.-Ebook_10_things_puppy.pdf',
      fileImage: '03.-Ebook_10_things_puppy_Page_01.png',
      description: `<p>We know this is a common time for pet carers to seek online Vet help so if you’re feeling overwhelmed or anxious, you’re not alone.</p>
            <p>Our Vets have put together ‘10 things your new puppy needs from you’ to give you the safest and happiest start together at home.</p>
            <p><strong>Features:</strong></p>
            <ul>
            <li>10 useful tips to take care of your new pup</li>
            <li>Food, training and vaccinations help</li>
            <li>Vet certified advice</li>
            </ul>`,
    },
    {
      title: 'Vetchat’s guide to choosing a new puppy',
      slug: 'vetchats-guide-to-choosing-a-new-puppy',
      featuredImage: '02.-Ebook_guide-to-choosing-a-new-puppy.png',
      file: '02.-Ebook_guide-to-choosing-a-new-puppy.pdf',
      fileImage: '02.-new-puupy-preview.png',
      description: `<p>When you’re looking at getting a puppy, falling in love with the idea of a breed you’ve always wanted is the easy part! What’s not so easy is thinking practically about what breed to choose. That’s what this guide is for.</p>
            <p>Here are five pieces of advice from our Vets on finding your perfect pup.</p>
            <p><strong>Features:</strong></p>
            <ul>
            <li>Common health problems to look out for</li>
            <li>Top 10 factors for choosing a pup</li>
            <li>Pre-puppy checklist</li>
            <li>15 pages of puppy goodness</li>
            </ul>`,
    },
    {
      title: 'Vetchat’s guide to choosing a new kitten',
      slug: 'vetchats-guide-to-choosing-a-new-kitten',
      featuredImage: '01.-Ebook_guide-to-choosing-a-new-kitten.png',
      file: '01.-Ebook_guide-to-choosing-a-new-kitten.pdf',
      fileImage: '00.-Ebook_guide-to-choosing-a-new-kitten.png',
      description: `<p>When you’re looking at getting a kitten, falling in love with the idea of a breed you’ve always wanted is the easy part! What’s not so easy is thinking practically about what breed to choose. That’s what this guide is for.</p>
            <p>Here are five pieces of advice from our Vets on finding your perfect kitty.</p>
            <p><strong>Features:</strong></p>
            <ul>
            <li>Common cat health problems</li>
            <li>Top 7 factors for choosing a cat</li>
            <li>Pre-kitten checklist</li>
            <li>13 page ebook</li>
            </ul>`,
    },
  ],
  infographics: [
    {
      title: 'Infographic: Your Dogs Health According to Their Poop',
      slug: 'infographic-your-dogs-health-according-to-their-poop',
      featuredImage: '02.-Poop.png',
      fileImage: '00.-Oh-poop-09.png',
    },
    {
      title: 'Infographic: Body Condition and Ideal Weight in Cats',
      slug: 'infographic-body-condition-and-ideal-weight-in-cats',
      featuredImage: '02.-Cat_idealweight.png',
      fileImage: '02.-body_condition_score-cat.png',
    },
    {
      title: 'Infographic: Ideal Body Condition for Dogs',
      slug: 'infographic-ideal-body-condition-for-dogs',
      featuredImage: '01.-Dog-BCS.png',
      fileImage: '01.-body_condition_score-dog.png',
    },
  ],
};

export const RESOURCE_TYPES = [
  { id: 2, name: 'eBooks' },
  { id: 1, name: 'Infographics' },
  { id: 3, name: 'Videos' },
];

export const PAGE_VIDEOS = {
  description: `Our mission is for every pet to be living their best life every day.
    
    We’re making pet care easier to access and understand for pet-parents everywhere. We’re sharing educational videos and obviously we would never want you to think that these answers are exactly for your fur baby––they’re not, if you need personalised advice for your pet, start a chat with one of our Vets.`,
};

export const GA_ID = 'UA-65622347-1';

export const GIVING = {
  image: 'wildlife_vetchat_pic.jpg',
  title: 'Giving',
  message: `With the support of our community, VetChat is supporting WIRES, wildlife rescue.\n
    WIRES mission is to actively rehabilitate and preserve Australian wildlife and inspire others to do the same.\n
    This is a service that has been rescuing and caring for native animals for over 30 years. They have over 2500 volunteers in 28 branches involved in the rescue and care of wildlife and we have a dedicated Rescue Office that operates 365 days a year assisting the community to help native animals in distress.\n    
    WIRES helps tens of thousands of animals every year, receiving up to 95,000 requests for rescue advice and assistance every 12 months.\n    
    VetChat is donating a dollar from every consultation to this wildlife saving service.`,

  link: 'https://www.wires.org.au/',
};

export const VetClinics = [
  {
    name: 'East Arnhem Veterinary Services, Nhulunbuy NT',
    email: 'eavetservices@gmail.com',
  },
];
