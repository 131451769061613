import React, { Component, Fragment } from 'react';

import { PAGES } from '../../constants/constants';
import { isEmail, isEmpty } from 'validator';
import axios from 'axios';
import { PropagateLoader } from 'react-spinners';
import { gaPageview } from '../../utils/dev';
import { Link } from 'react-router-dom';

const loaderStyle = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'center',
};

export default class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      name: '',
      email: '',
      message: '',
      errors: [],
      success: null,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  componentDidMount() {
    const page = PAGES['contact'];
    document.title = page.title;

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    gaPageview(this.props.location);
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmitting: true }, () => {
      let errors = [];
      //validating before submit
      const { name, email, message } = this.state;
      if (isEmpty(name)) {
        errors.push('Name is empty');
      }

      if (isEmpty(email)) {
        errors.push('Email is empty');
      } else if (!isEmail(email)) {
        errors.push('Email is invalid');
      }

      if (isEmpty(message)) {
        errors.push('Message is empty');
      }

      if (errors.length > 0) {
        this.setState({ errors: errors, isSubmitting: false });
      } else {
        //do submit message
        axios
          .post(axios.defaults.baseURL + '/contactus', {
            name,
            email,
            body: message,
          })
          .then(() => {
            this.setState({
              success: true,
              isSubmitting: false,
              name: '',
              email: '',
              message: '',
              errors: [],
            });
          })
          .catch((e) => {
            this.setState({
              isLoading: false,
              error: true,
              errors: e.response.data.errors,
            });
          });
        this.setState({ isSubmitting: false });
      }
    });
  }

  mapAboutVet(vetAbout, dr) {
    return vetAbout.map((about, index) => {
      return <p key={`about-${dr}-${index}`}>{about}</p>;
    });
  }

  render() {
    const mapErrors = this.state.errors.map((err, index) => {
      return <li key={`error-${index}`}>{err}</li>;
    });
    return (
      <Fragment>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 section-title header-contact'>
              <h2>Contact Us</h2>
            </div>
            <div className='col-sm-12'>
              <div className='contact-text'>
                <p>
                  <b>Hello!</b>
                </p>
                <p>
                  Please get in touch with any questions about using Vetchat.
                  You will typically hear from us within a business day.
                </p>
                <p>
                  We are unable to offer Veterinary advice over this channel,
                  but you can speak to one of our Vets online over
                  <Link to='/'> here</Link>.
                </p>
                <hr style={{ margin: '20px auto', width: '70%' }} />
              </div>
              <div className='form-contact space-bottom'>
                <form onSubmit={this.handleFormSubmit}>
                  <p>
                    Fields Marked <span className='required-red'>*</span> are
                    required
                  </p>
                  <div className='form-group'>
                    <label className=''>
                      Name <span className='required-red'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder=''
                      required
                      onChange={(event) =>
                        this.setState({ name: event.target.value })
                      }
                      value={this.state.name}
                    />
                  </div>
                  <div className='form-group'>
                    <label className=''>
                      Email <span className='required-red'>*</span>
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      placeholder=''
                      required
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                      value={this.state.email}
                    />
                  </div>
                  <div className='form-group'>
                    <label className=''>
                      Message <span className='required-red'>*</span>
                    </label>
                    <textarea
                      className='form-control'
                      placeholder=''
                      required
                      onChange={(event) =>
                        this.setState({ message: event.target.value })
                      }
                      value={this.state.message}
                      rows='7'
                    />
                  </div>
                  <div className='form-group'>
                    <div className='errors'>
                      {this.state.errors.length > 0 && <ul>{mapErrors}</ul>}
                    </div>
                    <button
                      type='submit'
                      disabled={this.state.isSubmitting}
                      className='btn btn-vetchat'
                    >
                      SUBMIT
                    </button>
                    {this.state.isSubmitting && (
                      <div style={loaderStyle}>
                        <PropagateLoader color={'#36D7B7'} />
                      </div>
                    )}
                    {this.state.success && (
                      <div>
                        <p className='alert alert-success'>
                          Your message has been sent. Thanks for taking the time
                          to contact us.
                        </p>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
