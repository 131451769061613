import React, { useEffect } from 'react';
import axios from 'axios';

const UserVideo = (props) => {
  const customer_id = props.match.params.customer_id;
  const consult_id = props.match.params.consult_id;

  useEffect(() => {
    const init = () => {
      axios
        .post('/user/login_cust_id', {
          customer_id: customer_id,
        })
        .then((response) => {
          let token = response.data.token;
          //removed  singleToken if exists
          localStorage.removeItem('singleToken');
          localStorage.setItem('token', token);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
          window.location.pathname = '/video-chat/' + consult_id;
          //window.location.reload();
        })
        .catch((e) => {
          alert('Error');
          console.log(e);
        });
    };
    init();
  }, [customer_id, consult_id]);

  return <div>User Video</div>;
};

export default UserVideo;
