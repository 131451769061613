import React from 'react';
import FormInput from '../components/FormInput';
import AjaxError from '../components/AjaxError';
import Loading from '../components/Loading';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { gaPageview } from '../utils/dev';
import { isValidPassword } from '../utils/auth';

const styles = {
  dialog: {
    text: {
      fontSize: 15,
    },
    button: {
      fontSize: 15,
    },
  },
};

export default class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.resetPwd = this.resetPwd.bind(this);

    this.state = {
      code: this.props.match.params.code,
      isLoading: false,
      error: false,
      errors: [],
      email: '',
      success: false,
      password: '',
    };
  }
  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    gaPageview(this.props.location);
  }

  resetPwd() {
    if (!isValidPassword(this.state.password)) {
      alert(
        `Passowrd must contain:\n>at least 8 characters\n>at least one lowercase letter (a-z)\n>at least one uppercase letter (A-Z)\n>at least one number (0-9)`
      );
      return;
    }
    this.setState({ isLoading: true });

    axios
      .post(axios.defaults.baseURL + '/user/resetpwd', {
        code: this.state.code,
        password: this.state.password,
      })
      .then(() => {
        this.setState({ success: true, isLoading: false });
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
          error: true,
          errors: e.response.data.errors,
        });
      });
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (this.state.error) {
      return (
        <AjaxError
          open={this.state.error}
          errors={this.state.errors}
          action={() => {
            this.setState({ error: false });
          }}
        />
      );
    }

    return (
      <div className='container space-small-bottom'>
        <Dialog open={this.state.success}>
          <DialogTitle style={styles.dialog.heading}>
            Password Reset Success
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={styles.dialog.text}>
              Your password have been successfully reset. Please login with your
              new password.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link to='/login' className='btn btn-success pull-right'>
              Login
            </Link>
          </DialogActions>
        </Dialog>

        {/* Heading */}
        <div className='row'>
          <div className='col-sm-8 col-sm-push-2'>
            <h1>Reset Password</h1>
          </div>
        </div>
        <br />

        {/* Code Label */}
        <div className='row'>
          <label className='col-sm-6 col-sm-push-3'>
            Please enter your reset code:
          </label>
          <div className='clear' />
          {/* Code Input */}
          {FormInput(
            this,
            'Reset Password Code',
            'code',
            'text',
            null,
            null,
            'col-sm-6 col-sm-push-3'
          )}
          <div className='clear' />
          {/* Password Label */}
          <label className='col-sm-6 col-sm-push-3'>
            Please enter your new password:
          </label>
          <div className='clear' />
          {/* Password Input */}
          {FormInput(
            this,
            'New Password',
            'password',
            'password',
            null,
            null,
            'col-sm-6 col-sm-push-3'
          )}
          <div className='clear' />
          {/* Submit Button */}
          <div className='col-sm-6 col-sm-push-3'>
            <button
              className='btn btn-vetchat pull-right'
              onClick={this.resetPwd}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }
}
